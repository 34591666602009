import axios from 'axios';

const apiUrl = 'https://api.uccoin.co/api'

export const fetchOverview = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/overview`)
        return response.data
    } catch (error) {
        console.error('Error fetching overview:', error);
        throw error;
    }
};

export const fetchSpot = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/wallets/spot`)
        return response.data.wallet

    } catch (error) {
        console.error('Error fetching spot:', error);
        throw error;
    }
};

export const fetchFuture = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/wallets/future`)
        return response.data.wallet
    } catch (error) {
        console.error('Error fetching spot:', error);
        throw error;
    }
};

export const fetchPow = async (pairType) => {
    try {
        const response = await axios.get(`${apiUrl}/v3/inno-coins`, {
            params: { pair_type: pairType }
        })
        return response.data.coinpair
    } catch (error) {
        console.error('Error fetching POW:', error);
        throw error;
    }
};

export const fetchSpotPnl = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/spot-pnl`)
        return response.data
    } catch (error) {
        console.error('Error fetching spot:', error);
        throw error;
    }
};

export const fetchFuturePnl = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/future-pnl`)
        return response.data
    } catch (error) {
        console.error('Error fetching futures:', error);
        throw error;
    }
};

export const generateAddress = async (wallet_type) => {
    try {
        const response = await axios.get(`${apiUrl}/v3/generate-wallet`, {
            params: { wallet_type: wallet_type }
        })
        return response.data
    } catch (error) {
        console.error('Error generating Wallets:', error);
        throw error;
    }
};


