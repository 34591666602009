import axios from "axios";

const apiUrl = 'https://api.uccoin.co/api'

export const getOpenOrders = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/trades/open-orders`);

        if (response && response.data) {
            console.log(response.data)
            const openOrders = response.data.orders;
            return openOrders;
        } else {
            throw new Error('Response data or coinpair is undefined.');
        }
    } catch (error) {
        console.error('Error fetching all pairs:', error);
        throw error;
    }
};

export const getOrderHistories = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/trades/order-histories`);

        if (response && response.data) {
            const orderHistories = response.data.orders;

            return orderHistories;
        } else {
            throw new Error('Response data or coinpair is undefined.');
        }
    } catch (error) {
        console.error('Error fetching all pairs:', error);
        throw error;
    }
};

export const getTradeHistory = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/trades/trade-histories`);

        if (response && response.data) {
            const tradeHistories = response.data.orders;

            return tradeHistories;
        } else {
            throw new Error('Response data or coinpair is undefined.');
        }
    } catch (error) {
        console.error('Error fetching all pairs:', error);
        throw error;
    }
};

export const getFunds = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/trades/funds`);

        if (response && response.data) {
            const funds = response.data.funds;

            return funds;
        } else {
            throw new Error('Response data or coinpair is undefined.');
        }
    } catch (error) {
        console.error('Error fetching all pairs:', error);
        throw error;
    }
};