<template>
  <div v-if="!loading">
    <v-row class="price-list">
      <v-col cols="6" class="text-left">
        <span class="list-title"> Price(USDT)<br /></span>
        <span class="redPrimary--text" v-for="(ask, index) in orders.asks" :key="index">
          {{ ask.unit_price }}</span><br />

      </v-col>
      <v-col cols="6" class="text-right">
        <span class="list-title">Amount({{ selectedCoin }})</span>
        <span class="white--text" v-for="(ask, index) in orders.asks" :key="index"><br />
          {{ ask.number_of_order }}</span>
      </v-col>
    </v-row>
    <v-row class="pt-2 ma-0">
      <v-col class="text-center pa-0 ma-0">
        <h6 class="ma-0 pa-0 greenPrimary--text font-weight-bold">
          {{ orders.price ? orders.price : 0 }}
        </h6>
        <span class="sub-caption">={{ orders.price ? orders.price : 0 }} USDT</span><br />
      </v-col>
    </v-row>
    <v-row class="price-list mt-0 pt-0">
      <v-col cols="6" class="text-left">
        <span class="greenPrimary--text" v-for="(bid, index) in orders.bids" :key="index"><br />
          {{ bid.unit_price }}</span>
      </v-col>
      <v-col cols="6" class="text-right">
        <span class="white--text" v-for="(bid, index) in orders.bids" :key="index"><br />
          {{ bid.number_of_order }}</span>
      </v-col>

    </v-row>
  </div>
  <div v-else>
    <v-card class="pa-2 mt-4">
      <v-skeleton-loader type="list-item-three-line" class="mx-auto" :loading="loading"></v-skeleton-loader>
    </v-card>
    <v-card class="pa-2 mt-4">
      <v-skeleton-loader type="list-item-three-line" class="mx-auto" :loading="loading"></v-skeleton-loader>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: true,
    price: '',
  }),
  props: {
    selectedCoin: {
      type: String,
      required: true,
    },
  },
  computed: {
    orders: function () {
      return this.$store.state.orders;
    },


  },

  watch: {
    selectedCoin: function () {
      clearInterval(this.interval);
      this.$store.commit("setOrders", []);
      this.fetchOrders();
      this.interval = setInterval(() => {
        this.fetchOrders();
      }, 10000);
    },
  },

  methods: {
    async fetchOrders() {
      try {
        this.$axios
          .get(`https://api.uccoin.co/api/v3/recent-trades`, {
            params: {
              name: this.selectedCoin + "USDT",
            },
          })
          .then((response) => {
            //Save Orders to vuex
            this.$store.commit("setOrders", response.data);
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
        return [];
      }
    },


  },
  mounted() {
    // setInterval(this.fetchPrice, 5000)
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    this.fetchOrders();
    this.interval = setInterval(() => {
      this.fetchOrders();
    }, 10000);
  },
  //Remove/Clear interval and data when component is destroyed
  beforeDestroy() {
    clearInterval(this.interval);
    this.$store.commit("setOrders", []);
  },
};
</script>
<style>
.price-list {
  font-size: 11px;
  color: #b3b3b3;
}

.list-title {
  font-size: 13px;
}

.sub-caption {
  font-size: 10px;
  color: #b3b3b3;
}
</style>
  