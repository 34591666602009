<template>
    <div v-if="!$vuetify.breakpoint.xs" cols="12" class="mt-3">
        <v-card class="pa-5" color="#272727" style="border-radius: 10px;">
            <v-data-table :loading="isLoading" :headers="headers" :items="margins" :items-per-page="20" class="transparent">
                <template v-slot:item.name="{ item }">
                    <div class="py-3 d-flex align-center">
                        <div class="mr-3">
                            <v-avatar size="30">
                                <v-img :src="item.image"></v-img>
                            </v-avatar>
                        </div>

                        <div class="mr-2 d-flex align-center">
                            <!-- <span class="mr-2" style="font-size:16px;">{{ item.coin_pair_name }}</span><br /> -->
                            <span class="">{{ (item.name) }}</span>
                            <!-- <span style="font-size: 14px;"> {{ item.date }}</span><br /> -->
                        </div>
                    </div>
                </template>
                <template v-slot:item.duration="{ item }">
                    <span style="font-size:12px;color:grey">{{ item.start_date }} - </span><span
                        style="font-size:12px;color:grey">{{ item.end_date }}</span><br />
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn color="primary" style="color: #1C1D28 ;" @click="closeMargin(item.id)">
                        {{ $t('wallet.closePosition') }}
                    </v-btn>
                    <!-- <v-btn dense color="primary" style="color: #1C1D28">Close Position</v-btn> -->
                </template>
            </v-data-table>
        </v-card>
    </div>

    <v-col v-else cols="12">

        <div v-if="!margins.length <= 0">
            <v-row align="center" justify="center" class="py-3"
                v-for=" { coin_pair, open_interest, opening_price, current_price, duration, start_date, end_date, leverage, income, id }  in  margins "
                style="border-bottom: 0.1px solid #b7bdc6;">

                <v-col sm="12">
                    <div class="d-flex align-center justify-space-between">
                        <div>
                            <!-- <v-avatar size="30" class="mr-2">
                            <v-img :src="coin_pair.image"></v-img>
                        </v-avatar> -->
                            <span style="font-size: 16px; font-weight: 500;"> {{ coin_pair.pair_name }} </span>
                        </div>
                        <span style="font-size: 16px; font-weight: 500; color: #0ECB81  ">{{ income }}</span>
                    </div>

                    <div class=" mt-2 d-flex align-center justify-space-between">
                        <span style="font-size: 14px; color: #b7bdc6;">{{ $t('wallet.openInterest') }}: </span>
                        <span style="font-size: 14px; font-weight: 500;"> {{ open_interest }}</span>
                    </div>

                    <div class=" mt-2 d-flex align-center justify-space-between">
                        <span style="font-size: 14px; color: #b7bdc6;">{{ $t('wallet.openingPrice') }}: </span>
                        <span style="font-size: 14px; font-weight: 500;"> {{ opening_price }}</span>
                    </div>

                    <div class=" mt-2 d-flex align-center justify-space-between">
                        <span style="font-size: 14px; color: #b7bdc6;">{{ $t('wallet.currentPrice') }}: </span>
                        <span style="font-size: 14px; font-weight: 500;"> {{ current_price }}</span>
                    </div>

                    <div class=" mt-2 d-flex align-center justify-space-between">
                        <span style="font-size: 14px; color: #b7bdc6;">{{ $t('wallet.leverage') }}</span>
                        <span style="font-size: 14px; font-weight: 500;"> {{ leverage }}</span>
                    </div>


                    <div class="mt-2 d-flex flex-column">
                        <span style="font-size: 14px; color: #b7bdc6;">{{ $t('wallet.remaining') }}</span>

                        <div class=" mt-2 d-flex align-center justify-space-between">
                            <span style="font-size: 14px; color: #b7bdc6;">{{ $t('wallet.startDate') }} : </span>
                            <span style="font-size: 14px; font-weight: 500;"> {{ start_date }}</span>
                        </div>

                        <div class=" mt-2 d-flex align-center justify-space-between">
                            <span style="font-size: 14px; color: #b7bdc6;">{{ $t('wallet.endDate') }} : </span>
                            <span style="font-size: 14px; font-weight: 500;"> {{ end_date }}</span>
                        </div>
                    </div>

                    <div class="mt-5">
                        <v-btn block dense color="primary" style="color: #1C1D28 ;" @click="closeMargin(id)">{{
                            $t('wallet.closePosition') }}</v-btn>
                    </div>

                </v-col>
            </v-row>
        </div>

        <div v-else class="d-flex align-center justify-center mt-10" style="height: 100%">
            <div class="d-flex flex-column align-center justify-center">
                <v-icon x-large color="#b7bdc6">
                    mdi mdi-note-search-outline
                </v-icon>
                <span style="font-size: 12px; color: #b7bdc6">{{ $t('wallet.noRecords') }}</span>
            </div>
        </div>
    </v-col>
</template>

<script>
export default {
    data() {
        return {
            isLoading: false,
            margins: [],
            headers: [
                {
                    value: "coin_pair.pair_name",
                    text: "Token",
                    align: "start",
                    sortable: true,
                },
                {
                    value: "open_interest",
                    text: "Open Interest",
                    align: "start",
                    sortable: true,
                },
                {
                    value: "opening_price",
                    text: "Entry Price",
                    align: "start",
                    sortable: true,
                },
                {
                    value: "current_price",
                    text: "Current Price",
                    align: "start",
                    sortable: true,
                },
                {
                    value: "leverage",
                    text: "Leverage",
                    align: "start",
                    sortable: true,
                },
                {
                    value: "duration",
                    text: "Remaining Contract Time",
                    align: "start",
                    sortable: true,
                },

                {
                    value: "income",
                    text: "Income",
                    align: "start",
                    sortable: true,
                },
                {
                    value: "actions",
                    text: "Action",
                    align: "start",
                    sortable: true,
                },
            ],
        }
    },

    mounted() {
        this.fetchMargins()
    },

    methods: {
        fetchMargins() {
            this.loading = true;
            this.$axios
                .get("https://api.uccoin.co/api/v2/client-margin")
                .then((response) => {
                    this.margins = response.data.margin;
                    console.log(this.margins)
                    this.loading = false;
                });
        },


        closeMargin(id) {
            this.$axios
                .get("https://api.uccoin.co/api/v3/futures/close", {
                    params: { future_id: id },
                })
                .then((response) => {
                    try {
                        this.isLoading = true; // Set loading state to true before processing the response
                        if (response.data.message == "closeRequest") {
                            this.$swal({
                                title: "Success",
                                text: "Please proceed to Customer Service for confirmation.",
                                icon: "success",
                                timer: null,
                                buttons: false,
                            });
                            this.$store.dispatch("setFutureWallet");
                            this.$router.push("/home");
                        } else if (response.data.message === "closePositionError") {
                            this.$swal({
                                html: `Remaining days: ${response.data.daysLeft} <br/>
                                        If your income amount is greater than or equal to ${response.data.futureTarget},
                                        or total assets is less than equal to ${response.data.futureFundsToAdd}, can be closed in advance directly.`,
                                icon: "error",
                                showCancelButton: false,
                            });
                            this.errorMessage.password = "Wrong transaction password";
                        } else {
                            this.$swal({
                                title: "Error",
                                text: "Something went wrong",
                                icon: "error",
                                timer: 3000,
                                buttons: false,
                            });
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        this.isLoading = false; // Set loading state to false after processing the response
                    }
                })

                .catch((error) => {
                    this.loading = false;
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        this.$swal({
                            title: "Error",
                            text: error.response.data.message,
                            icon: "error",
                            timer: 3000,
                            buttons: false,
                        });
                    } else if (error.request) {
                        // The request was made but no response was received
                        console.log(error.request);
                        this.$swal({
                            title: "Error",
                            text: "No response from the server",
                            icon: "error",
                            timer: 3000,
                            buttons: false,
                        });
                    } else {
                        // Something else happened in making the request that triggered an error
                        console.log("Error", error.message);
                        this.$swal({
                            title: "Error",
                            text: "An error occurred while making the request",
                            icon: "error",
                            timer: 3000,
                            buttons: false,
                        });
                    }
                });
        },

    }
}
</script>