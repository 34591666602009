<template>
  <v-row justify="center">

    <grid-layout v-if="!$vuetify.breakpoint.xs" :layout.sync="layout" :responsive-layouts="layouts" :col-num="12"
      :row-height="30" :is-draggable="draggable" :is-resizable="resizable" :vertical-compact="true"
      :use-css-transforms="true" :responsive="true" @breakpoint-changed="breakpointChangedEvent">

      <!-- Stats Header   -->
      <grid-item v-if="layout.length > 0" :key="layout[0].i" :static="layout[0].static" :x="layout[0].x" :y="layout[0].y"
        :w="layout[0].w" :h="layout[0].h" :i="layout[0].i">
        <v-row>
          <v-col v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl" color="#121212"
            cols="12" class="d-flex flex-row pa-5">

            <div class="d-flex align-center">
              <h4 class="mb-0 mr-2" style="cursor: pointer; font-weight: 500;" @click="openDialog()">
                {{ selectedCoin }}/USDT
                <br v-if="$vuetify.breakpoint.xs" />
              </h4>
              <v-btn icon text @click="openDialog()">
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </div>

            <div class="d-flex flex-column ml-10">
              <div class="d-flex flex-column ml-10">

                <v-skeleton-loader v-if="!price" :loading="!price" type="text" :width="'40px'"
                  :height="'20px'"></v-skeleton-loader>

                <div v-else>
                  <span :style="{
                    fontSize: '16px',
                    fontWeight: '500',
                    color: price > 0 ? '#0ECB81' : (price < 0 ? '#F6465D' : 'inherit')
                  }">
                    {{ price }}
                  </span>
                </div>

              </div>
            </div>

            <div class="d-flex flex-column ml-10">
              <span class="item-title">{{ $t('trade.change') }}</span>

              <v-skeleton-loader v-if="!change24hr" :loading="!change24hr" type="text" :width="'40px'"
                :height="'20px'"></v-skeleton-loader>

              <div v-else>
                <span style="font-size: 12px; font-weight: 500; color: #0ECB81 " class="mr-3" v-if="change24hr > 0">
                  {{ change24hr }}%
                </span>
                <span style="font-size: 12px; font-weight: 500;  color: #CA3F64 " v-else-if="change24hr < 0">{{
                  change24hr
                }}%</span>
                <span style="font-size: 12px; font-weight: 500; color: #B7BDC6" v-else>{{ change24hr }}%</span>
              </div>
            </div>

            <div class="d-flex flex-column ml-10">
              <span class="item-title">{{ $t('trade.high') }}</span>
              <v-skeleton-loader v-if="!high" :loading="!high" type="text" :width="'40px'"
                :height="'20px'"></v-skeleton-loader>
              <div v-else>
                <span class="item-value">{{ high }}</span>
              </div>
            </div>

            <div class="d-flex flex-column ml-10">
              <span class="item-title">{{ $t('trade.low') }}</span>
              <v-skeleton-loader v-if="!low" :loading="!low" type="text" :width="'40px'"
                :height="'20px'"></v-skeleton-loader>
              <div v-else>
                <span class="item-value">{{ low }}</span>
              </div>
            </div>

            <div class="d-flex flex-column ml-10">
              <span class="item-title"> {{ $t('trade.volume') }} ({{ selectedCoin }})</span>
              <v-skeleton-loader v-if="!volumeCCY" :loading="!volumeCCY" type="text" :width="'40px'"
                :height="'20px'"></v-skeleton-loader>
              <div v-else>
                <span class="item-value">
                  {{ formattedSymbol(volumeCCY) }} </span>
              </div>

            </div>

            <div class="d-flex flex-column ml-10">
              <span class="item-title">{{ $t('trade.volume') }} (USDT)</span>
              <v-skeleton-loader v-if="!volume" :loading="!volume" type="text" :width="'40px'"
                :height="'20px'"></v-skeleton-loader>

              <div v-else>
                <span class="item-value">
                  {{ formattedSymbol(volume) }}
                </span>
              </div>

            </div>
          </v-col>

          <v-col v-if="$vuetify.breakpoint.sm" col="12" class="pa-10">
            <v-row>
              <v-col cols="6">
                <div class="d-flex flex-column align-start" style="cursor: pointer" @click="openDialog()">
                  <div>
                    <div v-if="price !== null">
                      <span
                        :style="{ fontSize: '24px', fontWeight: '500', color: price > 0 ? '#0ECB81' : (price < 0 ? '#F6465D' : 'inherit') }">
                        {{ formattedPrice(price) }}
                      </span>
                      <br v-if="$vuetify.breakpoint.sm" />
                      <span style="font-size:14px">{{ selectedCoin }}/USDT</span>
                    </div>
                  </div>

                  <div class="d-flex align-center">
                    <span style="font-size: 12px; color: rgb(14, 203, 129)" class="mr-3">{{ change24hr }}</span>
                  </div>
                </div>
              </v-col>
              <v-col cols="6">

                <div class="d-flex align-center justify-space-between">
                  <span style="font-size: 14px; font-weight: 300; color:rgb(132, 142, 156);"> {{ $t('trade.high')
                  }}</span>
                  <span style="font-size: 14px; font-weight: 500;">{{ high }}</span>
                </div>

                <div class="d-flex align-center justify-space-between">
                  <span style="font-size: 14px; font-weight: 300; color:rgb(132, 142, 156);">{{ $t('trade.low') }}</span>
                  <span style="font-size: 14px; font-weight: 500;">{{ low }}</span>
                </div>

                <div class="d-flex align-center justify-space-between">
                  <span style="font-size: 14px; font-weight: 400; color:rgb(132, 142, 156);"> {{ $t('trade.volume') }} ({{
                    selectedCoin }})</span>
                  <span style="font-size: 14px; font-weight: 500;">{{ formattedSymbol(volume) }}</span>
                </div>

                <div class="d-flex align-center justify-space-between">
                  <span style="font-size: 14px; font-weight: 300; color:rgb(132, 142, 156);">{{ $t('trade.volume') }}
                    (USDT)</span>
                  <span style="font-size: 14px; font-weight: 500;">{{ formattedSymbol(volume) }}</span>
                </div>

              </v-col>

            </v-row>
          </v-col>

        </v-row>
      </grid-item>

      <!-- CoinList -->
      <grid-item style="overflow: auto;" v-if="layout.length > 1" :key="layout[1].i" :static="layout[1].static"
        :x="layout[1].x" :y="layout[1].y" :w="layout[1].w" :h="layout[1].h" :i="layout[1].i">
        <!-- Include the content of the first item -->

        <div class="mx-3 mt-4">
          <span style="font-size: 16px; font-weight: 600;">{{ $t('trade.markets') }}</span>
          <v-divider></v-divider>
        </div>

        <div class="mx-3">
          <SearchBarComponent :search.sync="search" :placeholder="$t('trade.search')" />
        </div>

        <div v-if="filteredData.length <= 0">
          <div class="d-flex align-center justify-center mt-10" style="height: 100%">
            <div class="d-flex flex-column align-center justify-center">
              <v-icon x-large color="#b7bdc6">
                mdi mdi-note-search-outline
              </v-icon>
              <span style="font-size: 12px; color: #b7bdc6">{{ $t('trade.noRecords') }}</span>
            </div>
          </div>
        </div>

        <!-- Wrap the content in a div with overflow-y: auto -->
        <div v-else>
          <div class="row-item pa-3" v-for="( item, index ) in  filteredData " :key="index"
            @click="handleRowClicked(item.coin_pair_name)" style="cursor: pointer">
            <div class="mt-2 d-flex justify-space-between ml-2">
              <div class="d-flex flex-column align-start">
                <v-avatar size="25" color="white">
                  <v-img :src="item.image"></v-img>
                </v-avatar>
                <span style="font-size: 12px;" class="value ">{{ (item.coin_pair_name)
                }}</span>
              </div>
              <div class="d-flex flex-column align-end mr-2">
                <span style="font-size: 12px;">${{ parseFloat(item.close).toFixed(3) }}</span>
                <span v-if="item.increase_in_value > 0" style="color:#0ECB81; font-size: 12px">
                  {{ parseFloat(item.increase_in_value).toFixed(4) }}%
                </span>
                <span v-if="item.increase_in_value < 0" style="color:#CA3F64; font-size: 12px">
                  {{ parseFloat(item.increase_in_value).toFixed(4) }}%
                </span>
              </div>
            </div>
          </div>

        </div>
      </grid-item>

      <!-- Trades Chart -->
      <grid-item style="overflow: auto;" v-if="layout.length > 2" :key="layout[2].i" :static="layout[2].static"
        :x="layout[2].x" :y="layout[2].y" :w="layout[2].w" :h="layout[2].h" :i="layout[2].i">

        <v-col v-if='!$vuetify.breakpoint.xs'>

          <div class="mb-2 d-flex flex-column align-start">
            <v-btn-toggle v-model="toggle" mandatory text dense>
              <v-btn value="chart">
                {{ $t('trade.chart') }}
              </v-btn>

              <v-btn value="coinInfo">
                {{ $t('trade.overview') }}
              </v-btn>
              <!-- <v-btn value="tradingData">
                Trading Data
              </v-btn> -->
            </v-btn-toggle>
          </div>



          <div v-if="toggle === 'chart'">
            <!-- Charts Coming Soon -->
            <div class="mb-2 d-flex flex-column align-start">
              <v-btn-toggle v-model="sltimeFrame" mandatory dense borderless >
                <v-btn @input="setTimeFrame()" value="1min">
                  1min
                </v-btn>
                <v-btn @input="setTimeFrame()" value="5min">
                  5min
                </v-btn>
                <v-btn @input="setTimeFrame()" value="1hr">
                  1hr
                </v-btn>
                <v-btn @input="setTimeFrame()" value="4hr">
                  4hr
                </v-btn>
                <v-btn @input="setTimeFrame()" value="1D">
                  1D
                </v-btn>
                <!-- <v-btn value="tradingData">
                  Trading Data
                </v-btn> -->
              </v-btn-toggle>
            </div>
            <div v-if="sltimeFrame === '1min'">
              <TVChartContainer/>
            </div>
            <div v-else-if="sltimeFrame === '5min'">
              <TVChartContainerFive />
            </div>
            <div v-else-if="sltimeFrame === '1hr'">
              <TVChartContainerOneHr/>
            </div>
            <div v-else-if="sltimeFrame === '4hr'">
              <TVChartContainerFourHr/>
            </div>
            <div v-else-if="sltimeFrame === '1D'">
              <TVChartContainerOneD/>
            </div>
          </div>

          <div v-else-if="toggle === 'coinInfo'">
            <CoinInfo :coinProfile="coinProfile" />
          </div>

          <!-- <div v-else-if="toggle === 'tradingData'">
            <TradingData />
          </div> -->
        </v-col>
      </grid-item>

      <!-- Buy and sell form -->
      <grid-item v-if="layout.length > 3" :key="layout[3].i" :static="layout[3].static" :x="layout[3].x" :y="layout[3].y"
        :w="layout[3].w" :h="layout[3].h" :i="layout[3].i">
        <v-container>
          <!-- Buy and sell form -->
          <v-row justify="center">

            <v-col cols="12">
              <v-btn-toggle grow v-model="toggleMarketType" mandatory text dense>
                <v-btn value="market" style="text-transform: capitalize;">
                  {{ $t('trade.markets') }}
                </v-btn>

                <v-btn value="limit" style="text-transform: capitalize">
                  {{ $t('trade.limit') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col cols="6">
              <v-form ref="buyForm" lazy-loading>

                <div class="d-flex align-center">
                  <span class="mr-3">{{ $t('trade.available') }}</span>
                  <span style="color: #0ECB81; font-size:14px; font-weight: 500">{{ balanceToDisplay }}</span>
                  <span class="ml-1" style="font-size:12px">(USDT)</span>
                </div>

                <div class="mt-3">
                  <v-text-field v-model="buyLimitPrice" :value="price" outlined dense
                    :disabled="isMarketDisabled"></v-text-field>
                </div>

                <div class="mt-1">
                  <v-text-field v-model="amount" :value="amount" outlined dense :rules="amountRules()"></v-text-field>
                </div>

                <div>
                  <v-slider v-model="amountPercentage" :tick-labels="ticksLabels" ticks="always" tick-size="5" step="25">
                  </v-slider>
                </div>

                <div class="mt-1">
                  <ButtonComponent :label="$t('trade.buy')" color="greenPrimary" :block="true" :onClick="confirm"
                    :large="true" />
                </div>

              </v-form>
            </v-col>

            <v-col cols="6">
              <v-form ref="sellForm" lazy-loading>
                <!-- <div>
                  <v-select outlined dense :items="['MARKET', 'LIMIT']" :menu-props="{ bottom: true, offsetY: true }"
                    v-model="marketType" class="centered-input">
                  </v-select>
                </div> -->

                <div class="d-flex align-center">
                  <span class="mr-3">{{ $t('trade.available') }}</span>
                  <span style="color: #0ECB81; font-size:14px; font-weight: 500">{{ sellBalanceToDisplay }}</span>
                  <span class="ml-1" style="font-size: 12px">({{ selectedCoin }})</span>
                </div>

                <div class="mt-3">
                  <v-text-field v-model="sellLimitPrice" :value="price" outlined dense
                    :disabled="isMarketDisabled"></v-text-field>
                </div>

                <div class="mt-1">
                  <v-text-field v-model="sellAmount" :value="sellAmount" outlined dense
                    :rules="sellAmountRules()"></v-text-field>
                </div>

                <div>
                  <v-slider v-model="sellAmountPercentage" ticks="always" :tick-labels="ticksLabels" tick-size="5"
                    step="25">
                  </v-slider>
                </div>

                <div class="mt-1">
                  <ButtonComponent :label="$t('trade.sell')" color="redPrimary" :block="true" :onClick="confirmSell"
                    :large="true" />
                </div>
              </v-form>
            </v-col>

          </v-row>
        </v-container>
      </grid-item>

      <!-- <div
        v-if='!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) && ($vuetify.breakpoint.width > 1627 || $vuetify.breakpoint.height > 970)'>
        <OrderBook :asks="asks" :bids="bids" :price="price" :selectedCoin="selectedCoin" />
      </div> -->

      <!-- Order Book  -->
      <grid-item style="overflow:auto;" v-if="layout.length > 4" :key="layout[4].i" :static="layout[4].static"
        :x="layout[4].x" :y="layout[4].y" :w="layout[4].w" :h="layout[4].h" :i="layout[4].i">
        <v-container>
          <OrderBook :asks="asks" :bids="bids" :price="price" :selectedCoin="selectedCoin" />
        </v-container>
      </grid-item>

      <!--Trades Info-->
      <grid-item style="overflow: auto" v-if="layout.length > 5" :key="layout[5].i" :static="layout[5].static"
        :x="layout[5].x" :y="layout[5].y" :w="layout[5].w" :h="layout[5].h" :i="layout[5].i">
        <v-container>
          <TradesInfo :selectedCoin="selectedCoin" :TradesData="trades" :price="price" />
        </v-container>
      </grid-item>

      <!-- Toggle Button -->
      <grid-item style="overflow: auto" v-if="layout.length > 6 && $vuetify.breakpoint.width < 1450" :key="layout[6].i"
        :static="layout[6].static" :x="layout[6].x" :y="layout[6].y" :w="layout[6].w" :h="layout[6].h" :i="layout[6].i">

        <v-container>
          <v-btn-toggle class="mb-3" v-model="toggleView" mandatory text dense>
            <v-btn value="orderBook" style="text-transform: capitalize;">
              {{ $t('trade.orderBook') }}
            </v-btn>
            <v-btn value="trades" style="text-transform: capitalize">
              {{ $t('trade.marketTrades') }}
            </v-btn>
          </v-btn-toggle>

          <div v-if="toggleView === 'orderBook'">
            <OrderBook :asks="asks" :bids="bids" :price="price" :selectedCoin="selectedCoin" />
          </div>

          <div v-else>
            <TradesInfo :selectedCoin="selectedCoin" :TradesData="trades" :price="price" />
          </div>
        </v-container>
      </grid-item>

      <!-- Tables  -->
      <grid-item style="overflow: auto;" v-if="layout.length > 7" :key="layout[7].i" :static="layout[7].static"
        :x="layout[7].x" :y="layout[7].y" :w="layout[7].w" :h="layout[7].h" :i="layout[7].i">
        <div>
          <v-container fluid>
            <v-tabs v-model="tab2" background-color="#00000000" bg-color="transparent">

              <v-tab value="one">
                {{ $t('trade.openOrder') }}
              </v-tab>
              <v-tab-item>
                <OpenOrdersTable />
              </v-tab-item>

              <v-tab value="two">
                {{ $t('trade.orderHistory') }}
              </v-tab>
              <v-tab-item>
                <OrderHistoryTable />
              </v-tab-item>

              <v-tab value="three">
                {{ $t('trade.tradeHistory') }}
              </v-tab>
              <v-tab-item>
                <TradeHistoryTable />
              </v-tab-item>

              <v-tab value="four">
                {{ $t('trade.funds') }}
              </v-tab>
              <v-tab-item>
                <FundsTable />
              </v-tab-item>

              <!-- <v-tab>
                Positions
              </v-tab> -->

              <!-- <v-tab-item> -->
              <!-- <PositionsTable /> -->
              <!-- </v-tab-item> -->

            </v-tabs>
          </v-container>

        </div>
      </grid-item>

    </grid-layout>

    <!-- mobile-view -->
    <v-col v-if='$vuetify.breakpoint.xs' cols="6">

      <div class="d-flex flex-column align-start" @click="openDialog()">
        <div>
          <div v-if="price !== null">
            <span
              :style="{ fontSize: '24px', fontWeight: '500', color: price > 0 ? '#0ECB81' : (price < 0 ? '#F6465D' : 'inherit') }">
              {{ formattedPrice(price) }}
            </span>
            <br v-if="$vuetify.breakpoint.xs" />
            <span v-if="$vuetify.breakpoint.xs" style="font-size:14px">{{ selectedCoin }}/USDT</span>
          </div>
        </div>

        <div class="d-flex align-center">
          <span style="font-size: 12px; color: rgb(14, 203, 129)" class="mr-3">{{ change24hr }}</span>
        </div>

        <!-- <span style="margin-left:10px;font-size:15px;font-weight:300px">{{ selectedCoin }}</span> 
            
            <v-icon style="margin-left:3px;" v-if="selectedMarketCoin.percent > 0" color="green">mdi-arrow-top-right-thin</v-icon>
            <v-icon style="margin-left:3px;" v-else-if="selectedMarketCoin.percent < 0" color="red">mdi-arrow-top-right-thin</v-icon>
    
            <v-chip style="margin-left:3px;" v-if="selectedMarketCoin.percent > 0" color="green">{{  parseFloat(selectedMarketCoin.percent).toFixed(2) }}%</v-chip>
            <v-chip style="margin-left:3px;"  v-else-if="selectedMarketCoin.percent < 0" color="red">{{  parseFloat(selectedMarketCoin.percent).toFixed(2) }}%</v-chip> -->
      </div>
    </v-col>

    <v-col v-if='$vuetify.breakpoint.xs' cols="6">
      <v-row>
        <v-col cols="12">
          <div class="d-flex align-center justify-space-between">
            <span style="font-size: 10px; font-weight: 400; color:rgb(132, 142, 156);"> {{ $t('trade.high') }}</span>
            <span style="font-size: 12px; font-weight: 700;">{{ high }}</span>
          </div>

          <div class="d-flex align-center justify-space-between">
            <span style="font-size: 10px; font-weight: 400; color:rgb(132, 142, 156);">{{ $t('trade.low') }}</span>
            <span style="font-size: 12px; font-weight: 700;">{{ low }}</span>
          </div>

          <div class="d-flex align-center justify-space-between">
            <span style="font-size: 10px; font-weight: 400; color:rgb(132, 142, 156);"> {{ $t('trade.volume') }} ({{
              selectedCoin }})</span>
            <span style="font-size: 12px; font-weight: 700;">{{ formattedSymbol(volume) }}</span>
          </div>

          <div class="d-flex align-center justify-space-between">
            <span style="font-size: 10px; font-weight: 400; color:rgb(132, 142, 156);">{{ $t('trade.volume') }}
              (USDT)</span>
            <span style="font-size: 12px; font-weight: 700;">{{ formattedSymbol(volume) }}</span>
          </div>

        </v-col>

      </v-row>
    </v-col>

    <!-- <v-col cols="6" class="text-end">
        <v-btn text to="/markets/trading_data"><v-icon>mdi-poll</v-icon>MARKET</v-btn> 
      </v-col> -->

    <!-- Dynamic view rendering -->
    <v-col v-if='$vuetify.breakpoint.xs' cols="12">
      <v-tabs v-model="tab" background-color="#00000000" bg-color="transparent" class="tabs-right">

        <v-tab value="one">{{ $t('trade.chart') }}</v-tab>
        <v-tab-item class="pa-1">
          <div class="mb-2 d-flex flex-column align-start">
            <v-btn-toggle v-model="sltimeFrame" mandatory dense borderless >
              <v-btn @input="setTimeFrame()" value="1min">
                1min
              </v-btn>
              <v-btn @input="setTimeFrame()" value="5min">
                5min
              </v-btn>
              <v-btn @input="setTimeFrame()" value="1hr">
                1hr
              </v-btn>
              <v-btn @input="setTimeFrame()" value="4hr">
                4hr
              </v-btn>
              <v-btn @input="setTimeFrame()" value="1D">
                1D
              </v-btn>
              <!-- <v-btn value="tradingData">
                Trading Data
              </v-btn> -->
            </v-btn-toggle>
          </div>
          <div v-if="sltimeFrame === '1min'">
            <TVChartContainer/>
          </div>
          <div v-else-if="sltimeFrame === '5min'">
            <TVChartContainerFive />
          </div>
          <div v-else-if="sltimeFrame === '1hr'">
            <TVChartContainerOneHr/>
          </div>
          <div v-else-if="sltimeFrame === '4hr'">
            <TVChartContainerFourHr/>
          </div>
          <div v-else-if="sltimeFrame === '1D'">
            <TVChartContainerOneD/>
          </div>
        </v-tab-item>

        <v-tab value="two">{{ $t('trade.orderBook') }}</v-tab>
        <v-tab-item class="pa-1">
          <OrderBook :isLoading="false" :asks="asks" :bids="bids" :price="price" :selectedCoin="selectedCoin" />
        </v-tab-item>

        <v-tab value="three">{{ $t('trade.trades') }}</v-tab>
        <v-tab-item class="pa-1">
          <TradesInfo :selectedCoin="selectedCoin" :TradesData="trades" :price="price" />
        </v-tab-item>

        <v-tab value="four">{{ $t('trade.overview') }}</v-tab>
        <v-tab-item class="pa-1">
          <CoinInfo :coinProfile="coinProfile" />

        </v-tab-item>
      </v-tabs>
    </v-col>

    <!--Open Orders Table-->
    <v-col v-if='$vuetify.breakpoint.xs' class="mt-1" cols="12" sm="12" md="12" lg="12"
      :style="{ border: !$vuetify.breakpoint.xs ? '1px solid #b7bdc6' : 'none', height: !$vuetify.breakpoint.xs ? '500px' : '' }">
      <v-tabs v-model="tab2" background-color="#00000000" bg-color="transparent">

        <v-tab value="one">
          {{ $t('trade.openOrder') }}
        </v-tab>
        <v-tab-item>
          <OpenOrdersTable />
        </v-tab-item>

        <v-tab value="two">
          {{ $t('trade.orderHistory') }}
        </v-tab>
        <v-tab-item>
          <OrderHistoryTable />
        </v-tab-item>

        <v-tab value="three">
          {{ $t('trade.tradeHistory') }}
        </v-tab>
        <v-tab-item>
          <TradeHistoryTable />
        </v-tab-item>

        <v-tab value="four">
          {{ $t('trade.funds') }}
        </v-tab>
        <v-tab-item>
          <FundsTable />
        </v-tab-item>

        <!-- <v-tab>
          Positions
        </v-tab> -->

        <!-- <v-tab-item> -->
        <!-- <PositionsTable /> -->
        <!-- </v-tab-item> -->

      </v-tabs>
    </v-col>

    <v-footer v-if='$vuetify.breakpoint.xs' height="90" app>
      <v-row jusitfy="center">
        <v-col cols=6>
          <v-btn @click="setBuyDialog" block color="#0ECB81">{{ $t('trade.buy') }}</v-btn>
        </v-col>

        <v-col cols=6>
          <v-btn @click="setSellDialog" block color="#F6465D">{{ $t('trade.sell') }}</v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <v-bottom-sheet scrollable v-model="isBuyandSellDialog">
      <v-sheet class="pa-1 text-center" height="570px">

        <div class="d-flex align-center justify-space-between">
          <div>
            {{ $t('trade.spot') }}
          </div>
          <v-btn text @click="isBuyandSellDialog = false">
            <v-icon>mdi mdi-close</v-icon>
          </v-btn>
        </div>

        <v-divider></v-divider>

        <v-col align="start">
          <v-btn-toggle grow v-model="toggleMarketType" mandatory text dense>
            <v-btn value="market" @click="toggleMarketType = 'market'; check()" style="text-transform: capitalize;">
              {{ $t('trade.markets') }}
            </v-btn>

            <v-btn value="limit" @click="toggleMarketType = 'limit'; check()" style="text-transform: capitalize">
              {{ $t('trade.limit') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col cols="12">
          <v-btn-toggle v-model="delegateType" color="transparent" class="full-width-toggle"
            @change="setAmountPercentage">
            <v-btn v-for="( type, index) in delegateTypes " :key="index" :value="type" large
              :class="delegateType === type ? 'white--text' : 'grey--text'"
              :color="delegateType === type ? delegateColor : 'greyPrimary'">
              {{ type }}
            </v-btn>
          </v-btn-toggle>

          <v-form :ref="delegateType === 'BUY' ? 'buyForm' : 'sellForm'" lazy-loading>
            <!-- Buy and Sell Toggle -->
            <div class="mt-5 d-flex align-center justify-space-between">
              <span>{{ $t(trade.available) }}</span>
              <span v-if="delegateType == 'BUY'">{{ balanceToDisplay }}</span>
              <span v-if="delegateType == 'SELL'">{{ sellBalanceToDisplay }}</span>
            </div>

            <div v-if="delegateType === 'BUY'">
              <div class="mt-3">

                <v-text-field v-model="buyLimitPrice" outlined dense v-if="toggleMarketType == 'market'"
                  :disabled="isMarketDisabled">
                </v-text-field>

                <v-text-field v-model="buyLimitPrice" outlined dense v-if="toggleMarketType == 'limit'" :dense="true"
                  :disabled="isMarketDisabled">
                </v-text-field>
              </div>

              <!-- FROM BUY -->
              <v-slider v-model="amountPercentage" ticks="always" :tick-labels="ticksLabels" tick-size="5" step="25">
              </v-slider>
            </div>

            <div v-else>
              <div class="mt-3">

                <v-text-field v-model="sellLimitPrice" outlined :value="price" v-if="toggleMarketType == 'market'" dense
                  :disabled="isMarketDisabled">
                </v-text-field>

                <v-text-field v-model="sellLimitPrice" outlined v-if="toggleMarketType == 'limit'" dense
                  :disabled="isMarketDisabled">
                </v-text-field>
              </div>

              <!-- FROM SELL -->
              <v-slider v-model="sellAmountPercentage" ticks="always" :tick-labels="ticksLabels" tick-size="5" step="25">
              </v-slider>
            </div>

            <div v-if="delegateType === 'BUY'" class="mt-1">
              <v-text-field placeholder="Enter Amount" v-model="amount" dense outlined :rules="amountRules()">
              </v-text-field>
            </div>

            <div v-else class="mt-3">
              <v-text-field placeholder="Enter Amount" v-model="sellAmount" value="sellAmount" dense outlined
                :rules="sellAmountRules()">
              </v-text-field>
            </div>
            <v-divider></v-divider>

            <div class="mt-2">
              <v-btn block large :color="delegateColor" @click="confirmAction()">
                <span class="text-capitalize">{{ delegateType === "BUY" ? "BUY" : "SELL" }}</span>
              </v-btn>
            </div>

          </v-form>
        </v-col>
      </v-sheet>
    </v-bottom-sheet>

    <v-dialog v-model="confirmDialog" persistent width="400px">
      <v-card width="400px">
        <v-card-title>
          <v-toolbar class="elevation-0 transparent">
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ $t('dialog.confirmation') }} </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="confirmationForm" lazy-loading>
            <v-row>
              <v-col cols="12">
                <label class="mb-3"> {{ $t('dialog.transPassword') }}</label>
                <v-text-field outlined v-model="password" :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="visible = !visible" :rules="passwordRules()" :type="visible ? 'text' : 'password'"
                  :error-messages="errorMessage.password" dense />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <ButtonComponent color="secondary" :large="true" label="Cancel" :onClick="confirmDialog = false" /> -->
          <v-btn text @click="confirmDialog = false">
            {{ $t('dialog.cancel') }}
          </v-btn>
          <v-btn style="color:#2b3139" color="primary" @click="trade(), (loader = 'loading')" :loading="loading"
            :disabled="loading">
            {{ $t('dialog.confirm') }}
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="coinsDialog" max-width="0" class="elevation-0" color="#00000000">
      <v-card class="right-dialog ">

        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="coinsDialog = false">
            <v-icon>mdi mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-row align="center">
          <v-col cols="12">
            <SearchBarComponent :search.sync="search" :placeholder="$t('trade.search')" />
            <v-data-table :headers="headers" :items-per-page="20" :items="filteredData" :hide-default-footer="false"
              class="transparent px-3" @click:row="rowClicked($event)">
              <template v-slot:item.name="{ item }">
                <v-row>
                  <v-col cols="3" class="text-center" style="margin-top:2px">
                    <v-avatar color="white" size="25">
                      <v-img :src="item.image"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <span style="margin-top:50px">{{ item.coin_pair_name }}</span><br />
                    <span style="font-size:10px;color:grey">{{ (item.coin_pair_name).replace('USDT', '') }}</span>
                    <!-- <span> {{ item.date }}</span><br/> -->
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.close="{ item }">
                <v-row>
                  <v-col cols="12">
                    <span>${{ parseFloat(item.close).toFixed(3) }}</span><br />
                    <!-- <span style="font-size:10px;color:grey">{{ (item.coin_pair_name).replace('USDT', '')}}</span> -->
                    <!-- <span> {{ item.date }}</span><br/> -->
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item.increase_in_value="{ item }">

                <span v-if="item.increase_in_value > 0" style="color:#0ECB81 ">{{
                  parseFloat(item.increase_in_value).toFixed(4) }}% <v-icon style="color:#0ECB81 " class="ml-0">mdi
                    mdi-trending-up</v-icon></span>
                <span v-if="item.increase_in_value < 0" style="color:#CA3F64  ">{{
                  parseFloat(item.increase_in_value).toFixed(4) }}% <v-icon style="color:#CA3F64  " class="ml-0">mdi
                    mdi-trending-down</v-icon></span>

              </template>
              <template v-slot:item.volume="{ item }">
                <span>{{ parseFloat(item.volume).toFixed(2) }}</span><br />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- <DialogComponent :bindData="confirmDialog" cardTitle="Confirmation" card="Test Card Text" /> -->
  </v-row>
</template>
<script>
import axios from 'axios'
import store from "@/store";
import TVChartContainer from '../../../components/TVChartContainer.vue';
import TVChartContainerFive from '../../../components/TVChartContainerFive.vue'
import TVChartContainerOneHr from '../../../components/TVChartContainerOneHr.vue'
import TVChartContainerFourHr from '../../../components/TVChartContainerFourHr.vue'
import TVChartContainerOneD from '../../../components/TVChartContainerOneD.vue'
import OrderBook from './OrderBook.vue';
import TradesInfo from './TradesInfo.vue';
import CoinInfo from './CoinInfo.vue'
import TradingData from './TradingData.vue';
// Tables
import OpenOrdersTable from './Tables/OpenOrdersTable.vue'
import OrderHistoryTable from './Tables/OrderHistoryTable.vue'
import TradeHistoryTable from './Tables/TradeHistoryTable.vue'
import FundsTable from './Tables/FundsTable.vue'
import PositionsTable from './Tables/PositionsTable.vue'

import TextfieldComponent from '@/components/ui/TextfieldComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue'
import DialogComponent from '@/components/ui/DialogComponent.vue';
import SearchBarComponent from '@/components/ui/SearchBarComponent.vue';

import { fetchCoinInfo } from '../../../services/tradeServices.js'

import numeral from 'numeral'
import Pusher from 'pusher-js';

import { GridLayout, GridItem } from "vue-grid-layout";

let testLayouts = {

  sm: [
    { "x": 0, "y": 0, "w": 12, "h": 4, "i": "0", static: true },
    { "x": 0, "y": 0, "w": 0, "h": 0, "i": "1", static: true }, //remove coin list
    { "x": 0, "y": 4, "w": 6, "h": 16, "i": "2", static: true }, //trade chart
    { "x": 0, "y": 20, "w": 6, "h": 10, "i": "3", static: true },//buy and sell form
    { "x": 0, "y": 0, "w": 0, "h": 0, "i": "4", static: false }, //order book
    { "x": 0, "y": 0, "w": 0, "h": 0, "i": "5", static: false },
    { "x": 0, "y": 30, "w": 6, "h": 23, "i": "6", static: true },
    { "x": 0, "y": 53, "w": 12, "h": 12, "i": "7", static: true },
  ],

  md: [
    { "x": 0, "y": 0, "w": 12, "h": 2, "i": "0", static: true },
    { "x": 0, "y": 0, "w": 0, "h": 0, "i": "1", static: true }, //remove coin list
    { "x": 0, "y": 2, "w": 6, "h": 16, "i": "2", static: true }, //trade chart
    { "x": 0, "y": 18, "w": 6, "h": 10, "i": "3", static: true },//buy and sell form
    { "x": 0, "y": 0, "w": 0, "h": 0, "i": "4", static: false }, //order book
    { "x": 0, "y": 0, "w": 0, "h": 0, "i": "5", static: false },
    { "x": 6, "y": 2, "w": 4, "h": 26, "i": "6", static: true },
    { "x": 0, "y": 28, "w": 12, "h": 12, "i": "7", static: true },
  ],

  lg: [
    { "x": 0, "y": 0, "w": 12, "h": 2, "i": "0", static: true },
    { "x": 0, "y": 2, "w": 2, "h": 26, "i": "1", static: true },
    { "x": 2, "y": 2, "w": 6, "h": 16, "i": "2", static: true },
    { "x": 2, "y": 18, "w": 6, "h": 10, "i": "3", static: true },
    { "x": 8, "y": 2, "w": 2, "h": 26, "i": "4", static: true },
    { "x": 10, "y": 2, "w": 2, "h": 26, "i": "5", static: true },
    { "x": 8, "y": 2, "w": 4, "h": 26, "i": "6", static: true },
    { "x": 10, "y": 28, "w": 12, "h": 12, "i": "7", static: true },
  ],
};

export default {
  components: {
    SearchBarComponent,
    TextfieldComponent,
    ButtonComponent,
    TVChartContainer,
    TVChartContainerFive,
    TVChartContainerOneHr,
    TVChartContainerFourHr,
    TVChartContainerOneD,
    OrderBook,
    TradesInfo,
    CoinInfo,
    TradingData,
    OpenOrdersTable,
    OrderHistoryTable,
    TradeHistoryTable,
    FundsTable,
    PositionsTable,
    GridLayout,
    GridItem
  },
  name: "chart",
  data: () => ({
    buyLimitPrice: '',
    sellLimitPrice: '',
    coin_base: '',
    timer1: '',
    search: '',
    isLoading: false,
    tab: "one",
    tab2: "one",
    getDataInterval: '',
    getHistoricalDataInterval: '',
    price: "",
    change24hr: null,
    close: null,
    high: null,
    low: null,
    open: null,
    time: null,
    volume: null,
    volumeCCY: null,
    layouts: testLayouts,
    layout: testLayouts["lg"],
    draggable: true,
    resizable: true,
    index: 0,
    // HighLow
    // Volume(BTC)
    // Volume(USDT)
    // data: [],

    historicalData: [],

    orderBookData: null,
    asks: [],
    bids: [],
    orderBookSocket: null,

    trades: [],
    tradesSocket: null,

    socket: null,
    isBuyandSellDialog: false,
    isOpenSellDialog: false,
    isOpenBuyDialog: false,

    toggle: undefined,
    toggleView: 'orderBook',
    toggleMarketType: 'market',
    coinsDialog: false,
    powPairs: [],
    ticksLabels: [
      '0',
      '25',
      '50',
      '75',
      '100',
    ],
    headers: [
      {
        value: "name",
        text: "Name",
        align: "start",
        sortable: true,
      },
      {
        value: "close",
        text: "Price",
        sortable: true,
        align: "start",
      },
      {
        value: "increase_in_value",
        text: "24h Change",
        sortable: true,
        align: "center",
      },
    ],
    navSelectedCoin: 0,
    loading: false,
    visible: false,
    marketCoinsDialog: false,
    delegateTypes: ["BUY", "SELL"],
    marketType: "MARKET",
    priceTypes: ["AMOUNT", "TOTAL"],
    priceType: "AMOUNT",
    amountPercentage: 0,
    sellAmountPercentage: 0,
    amount: 0,
    sellAmount: 0,
    sltimeFrame: '1min',
    password: null,
    coinProfile: [],
    // high: [],
    // low: [],
    minimum: {
      minimum_sell: 0,
      account_balance_limit_spot: 0,
    },
    errorMessage: {
      password: "",
    },
    dataRaw: {
      dates: [],
      series: [],
      current: {},
    },
    timeFrame: "1min",

    step: 1,
    // coinDetails: {
    //   open: 0,
    //   close: 0,
    //   high: 0,
    //   low: 0,
    //   volume: 0,
    // },
    latestDetails: {
      amount: 0,
      percent: 0,
    },
    previousPath: "",
    confirmDialog: false,
  }),

  computed: {
    // formattedPrice() {
    //   return formatPrice(this.priceFromGMSWAP, 2);
    // },

    filteredData() {
      if (!this.search) {
        return this.powPairs;
      }
      const searchTerm = this.search.toLowerCase();
      return this.powPairs.filter(item => item.coin_pair_name.toLowerCase().includes(searchTerm));
    },

    isMarketDisabled() {
      return this.toggleMarketType === 'market';
    },

    formattedPrice() {
      return (price) => numeral(price).format('0,0.00');
    },

    formattedChange() {
      return (volume) => numeral(volume).format('0,0.00')
    },

    formattedSymbol() {
      return (value) => numeral(value).format('0a')
    },

    delegateColor() {
      return this.delegateType == "SELL" ? "redPrimary" : "greenPrimary";
    },

    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    client() {
      return this.$store.getters.client;
    },
    delegateType: {
      get() {
        return this.$store.state.delegateType;
      },
      set(value) {
        this.$store.state.delegateType = value;
      },
    },
    selectedCoin: {
      get() {
        return this.$store.state.selectedCoin;
      },
      set(value) {
        this.$store.state.selectedCoin = value;
      },
    },
    selectedMarketCoin() {
      return this.$store.getters.marketCoins.find(
        (coin) => coin.name == this.selectedCoin
      );
    },
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    coins() {
      //Get name only
      return this.$store.getters.marketCoins.map((coin) => coin.name);
    },
    numberOfOrder() {
      if (this.priceType === "AMOUNT") {
        return this.amount / this.valuePerUnit;
      } else {
        return this.amount;
      }
    },

    sellNumberOfOrder() {
      return this.sellAmount;
    },
    total() {

      if (this.priceType === "AMOUNT") {
        return this.amount;
      } else {
        return this.amount * this.valuePerUnit;
      }
    },

    sellTotal() {
      return this.sellAmount * this.valuePerUnit;
    },
    valuePerUnit() {
      return this.selectedMarketCoin.value;
    },
    sellBalanceInUSDT() {
      let wallets = this.$store.getters.wallet.wallet;
      return wallets.find((item) => item.name === this.selectedCoin).usdt_value;
    },
    sellBalanceInCoin() {
      let wallets = this.$store.getters.wallet.wallet;
      let balance = wallets.find(
        (item) => item.name === this.selectedCoin
      ).coin_value;
      return balance;
    },
    buyBalanceInUSDT() {
      let wallets = this.$store.getters.wallet.wallet;
      return wallets.find((item) => item.name === "USDT").usdt_value;
    },
    buyBalanceInCoin() {
      let wallets = this.$store.getters.wallet.wallet;
      let balance =
        wallets.find((item) => item.name === "USDT").usdt_value /
        this.valuePerUnit;
      return balance;
    },
    balanceToDisplay() {

      return this.priceType == "TOTAL"
        ? this.buyBalanceInCoin
        : this.buyBalanceInUSDT
    },
    sellBalanceToDisplay() {
      return this.sellBalanceInCoin;
      // return 0;
    },
    minimumSell() {
      if (this.minimum.minimum_sell == "0") return 0;
      else {
        // if (this.priceType == "TOTAL")
        return this.minimum.minimum_sell;
        // else return this.minimum.minimum_sell * this.valuePerUnit;
      }
    },
    minimumBuy() {
      if (this.minimum.account_balance_limit_spot == "0") return 0;
      else {
        // if (this.priceType == "TOTAL")
        return this.minimum.account_balance_limit_spot;
        // else return this.minimum.account_balance_limit_spot * this.valuePerUnit;
      }
    },
    orders: function () {
      return this.$store.state.orders;
    },
  },

  created() {
    // Connect WebSocket when component is created
    // this.connectWebSocket();
    // this.subscribeToPusher()
  },
  beforeDestroy() {
    // Close WebSocket connection when component is destroyed
    if (this.socket) {
      this.socket.close();
    }
  },

  methods: {
    setTimeFrame()
    {
      sessionStorage.setItem('slTimeFrame',this.sltimeFrame)
    },

    breakpointChangedEvent(newBreakpoint, newLayout) {
      // Update the layout based on the current breakpoint
      if (newBreakpoint === "md") {
        this.layout = this.layouts.md;
      } else if (newBreakpoint === "lg") {
        this.layout = this.layouts.lg;
      }
      // Add more conditions for other breakpoints if needed
    },


    fetchTrades() {

      this.$axios.get('https://www.okx.com/api/v5/market/trades', {
        params: {
          instId: this.coin_base + '-USDT',
          limit: '100'
        }
      }).then(response => {
        // console.log(response)
        this.trades = response.data.data
      })
    },

    getHistoricalData() {
      
      const historicalDataString = sessionStorage.getItem("historicalData");
      // console.log(historicalDataString)

      // Check if historicalDataString is not null or undefined before parsing
      if (historicalDataString) {
        const historicalDataLocal = JSON.parse(historicalDataString);
        this.historicalData = historicalDataLocal;
        const chartBars = this.historicalData.bars[this.historicalData.bars.length - 1]
        this.price = chartBars.close
        this.high = chartBars.high
        this.low = chartBars.low     

      } else {
        // Handle the case when historicalDataString is null or undefined
      }

    },

    getData() {
      this.isLoading = true;

      axios.get('https://api.uccoin.co/api/v3/coin-assets', {
        params: {
          name: this.selectedCoin
        }
      })
        .then(response => {
          this.coin_base = response.data.coin.coin_base
          // First Axios request succeeded
          axios.get('https://www.okx.com/api/v5/market/ticker', {
            params: {
              instId: response.data.coin.coin_base + '-USD-SWAP'
            }
          })
            .then(response => {
              // Second Axios request succeeded
              this.volume = response.data.data[0].vol24h;
              this.volumeCCY = response.data.data[0].volCcy24h;
          

              if (this.selectedMarketCoin) {
                this.change24hr = this.selectedMarketCoin.percent;
              }

            })
            .catch(error => {
              // Second Axios request failed
              console.error('Error fetching data:', error);
            })
            .finally(() => {
              // Ensure isLoading is set to false after the second request completes
              this.isLoading = false;
            });
        })
        .catch(error => {
          // First Axios request failed
          console.error('Error fetching coin assets:', error);
          this.isLoading = false; // Ensure isLoading is set to false in case of error
        });
    },


    connectWebSocket() {
      this.ws = new WebSocket('wss://ws.okx.com:8443/ws/v5/public');

      this.ws.addEventListener('open', () => {
        console.log('WebSocket connected');

        // Subscribe to BTC price updates
        const subscriptionMessage = {
          "op": "subscribe",
          "args": [
            {
              "channel": "tickers",
              "instId": `${this.selectedCoin}-USDT`
            }
          ]
        };

        this.ws.send(JSON.stringify(subscriptionMessage));
      });

      this.ws.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };

      this.ws.addEventListener('message', (event) => {
        try {
          const message = JSON.parse(event.data);
          if (message && message.data && Array.isArray(message.data) && message.data.length > 0) {
            let lastValue = message.data[0]?.last;
            if (typeof lastValue !== 'undefined') {
              // Assign the value of lastValue to priceFromGMSWAP
              let priceFromOkx = lastValue;

              // Assign priceFromGMSWAP to this.priceFromGMSWAP
              this.price = priceFromOkx; 
            }
          }
        } catch (error) {
          console.error("Error parsing message:", error);
        }
      });

      this.ws.onclose = () => {
        console.log('Connection closed');
      };
    },

    setBuyDialog() {
      this.isBuyandSellDialog = true;
      this.delegateType = 'BUY'; // Optionally, set the delegateType to 'BUY'
    },

    setSellDialog() {
      this.isBuyandSellDialog = true;
      this.delegateType = 'SELL'; // Optionally, set the delegateType to 'SELL'
    },

    confirmAction() {
      if (this.delegateType === 'BUY') {
        this.confirm();
      } else {
        this.confirmSell();
      }
    },

    rowClicked(value) {
      console.log(value)
      const name = (value.coin_pair_name).replace('USDT', '')
      this.$store.state.selectedCoin = name;
      if (this.$route.path !== `/trades/trade-chart/${name}`) {
        this.$router.push(`/trades/trade-chart/${name}`);
        this.coinsDialog = false
        location.reload()
      }
    },

    handleRowClicked(value) {
      console.log(value)
      const name = (value).replace('USDT', '')
      this.$store.state.selectedCoin = name;
      if (this.$route.path !== `/trades/trade-chart/${name}`) {
        this.$router.push(`/trades/trade-chart/${name}`);
        location.reload()
      }
    },

    handleBuyandSellClicked() {
      this.isOpenBuyDialog = true
      this.isOpenSellDialog = true
    },

    closeDialog() {
      this.isOpenBuyDialog = false
      this.isOpenSellDialog = false
    },

    setCurrentComponent(componentName) {
      if (this.currentComponent !== componentName) {
        this.currentComponent = componentName;
      }
    },

    // async fetchCoinInfoData() {
    //   try {
    //     let coinProfile = JSON.parse(localStorage.getItem('coinProfile'));
    //     let high = JSON.parse(localStorage.getItem('high'));
    //     let low = JSON.parse(localStorage.getItem('low'));

    //     if (!coinProfile || !high || !low) {
    //       const { coinProfile: fetchedCoinProfile, high: fetchedHigh, low: fetchedLow } = await fetchCoinInfo(this.$store.state.selectedCoin);
    //       if (!coinProfile) {
    //         coinProfile = fetchedCoinProfile;
    //         localStorage.setItem('coinProfile', JSON.stringify(coinProfile));
    //       }
    //       if (!high) {
    //         high = fetchedHigh;
    //         localStorage.setItem('high', JSON.stringify(high));
    //       }
    //       if (!low) {
    //         low = fetchedLow;
    //         localStorage.setItem('low', JSON.stringify(low));
    //       }
    //     }

    //     this.coinProfile = coinProfile;
    //     this.high = high;
    //     this.low = low;

    //   } catch (error) {
    //     console.error('Error fetching all pairs:', error);
    //   }
    // },

    openTrade() {
      const name = this.$store.state.selectedCoin
      this.$router.push(`/trades/spot/${name}`)
    },

    pow() {
      axios.get('https://api.uccoin.co/api/v3/all-pairs', {
        params: {
          client_id: this.client.id
        }

      })
        .then(response => {
          this.powPairs = response.data.coinpair
        })
    },

    openDialog() {
      // console.log(this.dialog); // Log the current value of dialog
      this.coinsDialog = true;
    },

    closeDepositDialog() {
      this.coinsDialog = false;
    },

    selectCoin(coin) {
      this.$router.push(`/trades/spot/${coin.name}`);
      this.navSelectedCoin = this.marketCoins.indexOf(coin) + 1;
      this.selectedCoin = coin.name;
      this.fetchMinimum(coin.name);
      location.reload()
      // // this.$route.params.coin = coin.name;
      // this.marketCoinsDialog = false;
    },

    fetchMinimum(coin) {
      this.$axios
        .get(`https://api.uccoin.co/api/v3/getOrder?symbol=${coin}`)
        .then((response) => {
          this.minimum = response.data.price;
        });
    },

    // amountRules() {
    //   return this.delegateType === "SELL"
    //     ? [
    //       (v) => !!v || "Amount is required",
    //       (v) =>
    //         v >= this.minimumSell ||
    //         `Amount must be greater than minimum sell amount (${this.minimumSell})`,
    //       (v) =>
    //         v <= this.balanceToDisplay ||
    //         `Insufficient balance (${this.balanceToDisplay})`,
    //     ]
    //     : [
    //       (v) => !!v || "Amount is required",
    //       (v) =>
    //         v >= this.minimumBuy ||
    //         `Amount must be greater than minimum buy amount (${this.minimumBuy})`,
    //       (v) =>
    //         v <= this.balanceToDisplay ||
    //         `Insufficient balance (${this.balanceToDisplay})`,
    //     ];
    // },

    // amountRules() {
    //   return [
    //     (v) => !!v || "Amount is required",
    //     (v) =>
    //       v >= this.minimumBuy ||
    //       `Amount must be greater than minimum buy amount (${this.minimumBuy})`,
    //     (v) =>
    //       v <= this.balanceToDisplay ||
    //       `Insufficient balance (${this.balanceToDisplay})`,
    //   ];
    // },

    amountRules() {
      return [
        (v) => !!v || "Amount is required",
        (v) => (v >= Number(this.minimumBuy)) || `Amount must be greater than minimum buy amount (${Number(this.minimumBuy)})`,
        (v) => (v <= Number(this.balanceToDisplay)) || `Insufficient balance (${this.balanceToDisplay})`,
      ];
    },

    sellAmountRules() {
      return [
        (v) => !!v || "Amount is required",
        (v) =>
          v >= Number(this.minimumSell) ||
          `Amount must be greater than minimum sell amount (${this.minimumSell})`,
        (v) =>
          v <= Number(this.sellBalanceToDisplay) ||
          `Insufficient balance (${this.sellBalanceToDisplay})`,
      ];
    },

    passwordRules() {
      return [(v) => !!v || "Password is required"];
    },

    // confirm() {
    //   if (this.$refs.tradeForm.validate()) this.confirmDialog = true;
    // },

    confirm() {
      // alert(this.buyLimitPrice)
      // console.log(this.price)
      this.delegateType = 'BUY'
      if (this.$refs.buyForm.validate())
        this.confirmDialog = true;
    },

    confirmSell() {
      this.delegateType = 'SELL'
      if (this.$refs.sellForm.validate()) this.confirmDialog = true;
    },

    trade() {
      if (this.toggleMarketType == 'MARKET' || this.toggleMarketType == 'market') {
        if (this.delegateType == 'BUY') {
          if (this.$refs.confirmationForm.validate()) {
            this.loading = true;
            this.$axios
              .get("https://api.uccoin.co/api/v2/my-order", {
                params: {
                  name: this.selectedCoin,
                  delegate_type: this.delegateType,
                  number_of_order: this.numberOfOrder,
                  total: this.total,
                  order_per_unit: this.valuePerUnit,
                  trade_type: this.toggleMarketType,
                  market_type: this.marketType,
                  transaction_password: this.password,
                },
              })
              .then((response) => {
                this.loading = false;
                if (response.data.message == "success") {
                  this.$swal({
                    title: "Success",
                    text: "Order created successfully",
                    icon: "success",
                    timer: 3000,
                    buttons: false,
                  });
                  this.$store.dispatch("setWallet");
                  this.confirmDialog = false;
                } else if (response.data.message === "wrong_transaction_password") {
                  this.$swal({
                    title: "Error",
                    text: "Wrong transaction password",
                    icon: "error",
                    timer: 3000,
                    buttons: false,
                  });
                  this.errorMessage.password = "Wrong transaction password";
                } else {
                  this.$swal({
                    title: "Error",
                    text: "Something went wrong",
                    icon: "error",
                    timer: 3000,
                    buttons: false,
                  });
                }
              })
              .catch((error) => {
                this.loading = false;
                this.$swal({
                  title: "Error",
                  text: error.response.data.message,
                  icon: "error",
                  timer: 3000,
                  buttons: false,
                });
              });
          }
        }
        else if (this.delegateType == 'SELL') {
          if (this.$refs.confirmationForm.validate()) {
            this.loading = true;
            this.$axios
              .get("https://api.uccoin.co/api/v2/my-order", {
                params: {
                  name: this.selectedCoin,
                  delegate_type: this.delegateType,
                  number_of_order: this.sellNumberOfOrder,
                  total: this.sellTotal,
                  order_per_unit: this.valuePerUnit,
                  trade_type: this.toggleMarketType,
                  market_type: this.marketType,
                  transaction_password: this.password,
                },
              })
              .then((response) => {
                this.loading = false;
                if (response.data.message == "success") {
                  this.$swal({
                    title: "Success",
                    text: "Order created successfully",
                    icon: "success",
                    timer: 3000,
                    buttons: false,
                  });
                  this.$store.dispatch("setWallet");
                  this.confirmDialog = false;
                } else if (response.data.message === "wrong_transaction_password") {
                  this.$swal({
                    title: "Error",
                    text: "Wrong transaction password",
                    icon: "error",
                    timer: 3000,
                    buttons: false,
                  });
                  this.errorMessage.password = "Wrong transaction password";
                } else {
                  this.$swal({
                    title: "Error",
                    text: "Something went wrong",
                    icon: "error",
                    timer: 3000,
                    buttons: false,
                  });
                }
              })
              .catch((error) => {
                this.loading = false;
                this.$swal({
                  title: "Error",
                  text: error.response.data.message,
                  icon: "error",
                  timer: 3000,
                  buttons: false,
                });
              });
          }
        }
      }

      else {
        if (this.delegateType == 'BUY') {
          if (this.$refs.confirmationForm.validate()) {
            this.loading = true;
            this.$axios
              .get("https://api.uccoin.co/api/v2/my-order", {
                params: {
                  name: this.selectedCoin,
                  delegate_type: this.delegateType,
                  number_of_order: this.numberOfOrder,
                  total: this.total,
                  order_per_unit: this.buyLimitPrice,
                  trade_type: this.toggleMarketType,
                  market_type: this.marketType,
                  transaction_password: this.password,
                },
              })
              .then((response) => {
                this.loading = false;
                if (response.data.message == "success") {
                  this.$swal({
                    title: "Success",
                    text: "Order created successfully",
                    icon: "success",
                    timer: 3000,
                    buttons: false,
                  });
                  this.$store.dispatch("setWallet");
                  this.confirmDialog = false;
                } else if (response.data.message === "wrong_transaction_password") {
                  this.$swal({
                    title: "Error",
                    text: "Wrong transaction password",
                    icon: "error",
                    timer: 3000,
                    buttons: false,
                  });
                  this.errorMessage.password = "Wrong transaction password";
                } else {
                  this.$swal({
                    title: "Error",
                    text: "Something went wrong",
                    icon: "error",
                    timer: 3000,
                    buttons: false,
                  });
                }
              })
              .catch((error) => {
                this.loading = false;
                this.$swal({
                  title: "Error",
                  text: error.response.data.message,
                  icon: "error",
                  timer: 3000,
                  buttons: false,
                });
              });
          }
        }
        else if (this.delegateType == 'SELL') {
          if (this.$refs.confirmationForm.validate()) {
            this.loading = true;
            this.$axios
              .get("https://api.uccoin.co/api/v2/my-order", {
                params: {
                  name: this.selectedCoin,
                  delegate_type: this.delegateType,
                  number_of_order: this.sellNumberOfOrder,
                  total: this.sellTotal,
                  order_per_unit: this.sellLimitPrice,
                  trade_type: this.toggleMarketType,
                  market_type: this.marketType,
                  transaction_password: this.password,
                },
              })
              .then((response) => {
                this.loading = false;
                if (response.data.message == "success") {
                  this.$swal({
                    title: "Success",
                    text: "Order created successfully",
                    icon: "success",
                    timer: 3000,
                    buttons: false,
                  });
                  this.$store.dispatch("setWallet");
                  this.confirmDialog = false;
                } else if (response.data.message === "wrong_transaction_password") {
                  this.$swal({
                    title: "Error",
                    text: "Wrong transaction password",
                    icon: "error",
                    timer: 3000,
                    buttons: false,
                  });
                  this.errorMessage.password = "Wrong transaction password";
                } else {
                  this.$swal({
                    title: "Error",
                    text: "Something went wrong",
                    icon: "error",
                    timer: 3000,
                    buttons: false,
                  });
                }
              })
              .catch((error) => {
                this.loading = false;
                this.$swal({
                  title: "Error",
                  text: error.response.data.message,
                  icon: "error",
                  timer: 3000,
                  buttons: false,
                });
              });
          }
        }
      }


    },

    ratingText(index) {
      return index == 0
        ? "25%"
        : index == 1
          ? "50%"
          : index == 2
            ? "75%"
            : "100%";
    },

    setAmountPercentage() {
      if (this.amountPercentage == 0) {
        this.amount = 0;
      }

      if (this.amountPercentage == 25) {
        this.amount = this.balanceToDisplay / 4;
      }
      if (this.amountPercentage == 50) {
        this.amount = this.balanceToDisplay / 2;
      }
      if (this.amountPercentage == 75) {
        this.amount = this.balanceToDisplay / 4 + (this.balanceToDisplay / 2);
      }
      if (this.amountPercentage == 100) {
        this.amount = this.balanceToDisplay;
      }


      // console.log(this.amountPercentage, this.amount)
    },

    setSellAmountPercentage() {
      if (this.amountPercentage == 0) {
        this.sellAmount = 0;
      }

      if (this.sellAmountPercentage == 25) {
        this.sellAmount = this.sellBalanceInCoin / 4;
      }
      if (this.sellAmountPercentage == 50) {
        this.sellAmount = this.sellBalanceInCoin / 2;
      }
      if (this.sellAmountPercentage == 75) {
        this.sellAmount = this.sellBalanceInCoin / 4 + (this.sellBalanceInCoin / 2);
      }
      if (this.sellAmountPercentage == 100) {
        this.sellAmount = this.sellBalanceInCoin;
      }
    },

    async getTradesChart() {
      try {
        const response = await this.$axios.get(
          `https://api.uccoin.co/api/v2/chart/chart-series?symbol=${this.selectedCoin}&timeframe=${this.timeFrame}`
        );
        //Save marketCoins to vuex
        // this.$store.commit("setMarketCoins", response.data.coinpair);
        this.dataRaw = response.data;
        //Get current price
        this.coinDetails.amount = this.dataRaw.latest_time.close;
        this.latestDetails = this.dataRaw.latest_time;
        //Get percent change
        // this.coinDetails.percent = this.dataRaw.latest_time.percent;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    async fetchOrders() {
      try {
        this.$axios
          .get(`https://api.uccoin.co/api/v2/recent-trades`, {
            params: {
              name: this.selectedCoin + "USDT",
            },
          })
          .then((response) => {
            //Save Orders to vuex
            this.$store.commit("setOrders", response.data);
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  },

  watch: {
    amountPercentage(newVal) {
      this.setAmountPercentage();
    },

    sellAmountPercentage(newVal) {
      this.setSellAmountPercentage();
    }
  },

  mounted() {
    this.getHistoricalDataInterval = setInterval(this.getHistoricalData, 100);

    this.getData()
    this.fetchTrades()
    this.timer1 = setInterval(this.fetchTrades, 3000)

    this.getHistoricalData()

    this.getDataInterval = setInterval(this.getData, 10000);

    // this.getHistoricalDataInterval = setInterval(this.getHistoricalData, 100);

    axios.get('https://api.uccoin.co/api/v3/coin-assets', {
      params: {
        name: this.selectedCoin
      }
    }).then(response => {
      this.orderBookSocket = new WebSocket('wss://ws.okx.com:8443/ws/v5/public');
      this.orderBookSocket.onerror = (error) => {
        console.error('Order Book WebSocket Error:', error);
      };

      var orderCoin = response.data.coin.coin_base + '-USDT';
      this.orderBookSocket.addEventListener('open', () => {
        // console.log('Order Book WebSocket connected');

        // Subscribe to order book updates
        const subscriptionMessage = {
          "op": "subscribe",
          "args": [
            {
              "channel": "books5",
              "instId": orderCoin
            }
          ]
        };

        this.orderBookSocket.send(JSON.stringify(subscriptionMessage));
      });

      this.orderBookSocket.addEventListener('message', (event) => {
        try {
          const message = JSON.parse(event.data);
          if (message && message.event === 'subscribe') {
            // console.log("Subscription confirmed:", message);
          } else if (message && message.data && Array.isArray(message.data) && message.data.length > 0) {
            const orderBookData = message.data[0]; // Assuming order book data is the first element of the array
            if (orderBookData && orderBookData.asks && Array.isArray(orderBookData.asks) &&
              orderBookData.bids && Array.isArray(orderBookData.bids)) {
              this.orderBookData = orderBookData;
              this.asks = orderBookData.asks;
              // console.log(this.asks)
              this.bids = orderBookData.bids;
            } else {
              console.error("Invalid format for order book data:", orderBookData);
            }
          } else {
            console.error("Order book message data is invalid or empty:", event.data);
          }
        } catch (error) {
          console.error("Error parsing order book message:", error);
        }
      });

      this.orderBookSocket.onclose = () => {
        console.log('Connection closed');
      };

      this.tradesSocket = new WebSocket('wss://ws.okx.com:8443/ws/v5/public');

      this.tradesSocket.onerror = (error) => {
        console.log('Trade Web Socket Error', error);
      }

      this.tradesSocket.addEventListener('open', () => {
        console.log('Trades Websocket Connected');

        const subscriptionMessage = {
          "op": "subscribe",
          "args": [
            {
              "channel": "trades",
              "limit": "100",
              "instId": "BTC-USDT"
            }
          ]
        };

        this.tradesSocket.send(JSON.stringify(subscriptionMessage));
        // console.log(subscriptionMessage)
      });

      this.tradesSocket.addEventListener('message', (event) => {
        try {
          const message = JSON.parse(event.data);

          if (message && message.data && Array.isArray(message.data) && message.data.length > 0) {
            let tradesValue = message.data;
            // this.trades = tradesValue; // Assigning tradesValue to this.trades
            // console.log(this.trades)
          }

          // console.log(this.trades)
        } catch (error) {
          console.error("Error parsing trades")
        }
      })

      this.tradesSocket.onclose = () => {
        console.log('Connection closed');
      };
    })

    this.pow()
    // this.fetchCoinInfoData()

    this.selectedCoin = this.$route.params.coin;
    if (this.$store.getters.isAuthenticated) {
      this.fetchOrders();
      this.$store.dispatch("setWallets");
      this.fetchMinimum(this.selectedCoin);
    }
  },

  beforeDestroy() {
    // Close WebSocket connection when component is destroyed
    if (this.orderBookSocket) {
      this.orderBookSocket.close();
    }

    clearInterval(this.getHistoricalDataInterval)
    clearInterval(this.getDataInterval)
  },

  beforeRouteEnter(to, from, next) {
    const isAuthenticated = store.getters.isAuthenticated;
    if (isAuthenticated) {
      next();
    } else {
      next("/signin");
    }
  },
};
</script>
<style scoped>
.grid-layout {
  width: 100%;
  height: 500px;
  /* Set a fixed height for the grid layout */
}

.grid-item {
  /* background-color: #f0f0f0; */
  /* border: 1px solid #ccc; */
  padding: 20px;
  text-align: center;
}

.row-item:hover {
  background-color: #909090;
  /* Adjust the background color as needed */
}

.item-title {
  font-size: 12px;
  color: #b7bdc6;
}

.item-value {
  font-size: 12px;
  font-weight: 500;
}

.right-dialog {
  left: 0;
  top: 0;
  position: fixed;
  margin: 0;
  transform: translate(0);
  width: 500px;
  height: 100vh;
  overflow-y: auto;
}

.toolbar-buttons {
  background-color: transparent !important;
}

.trade-container {
  width: 100vw !important;
  border-radius: 20px !important;
  background-color: #1e1e1e !important;
}

.full-width-toggle {
  display: flex;
  width: 100%;
}

.full-width-toggle .v-btn {
  flex-grow: 1;
  flex-basis: 0;
}

.v-text-field.v-text-field--solo .v-input__control {
  height: 28px !important;
}

.v-text-field.v-text-field--solo .v-input__control .v-input__slot {
  height: 28px !important;
}

.price-list {
  font-size: 11px;
  color: #b3b3b3;
}

.list-title {
  font-size: 13px;
}

.sub-caption {
  font-size: 10px;
  color: #b3b3b3;
}

.rating-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-with-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-label {
  margin-top: 0px;
  font-size: 9px;
}

/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

.volume-caption {
  font-size: 10px !important;
  margin-bottom: 0px !important;
  color: #b3b3b3;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.vue-grid-layout {
  background: #000;
  /* height: 100vh; */
  /* height: auto !important; */
}

.vue-grid-item:not(.vue-grid-placeholder) {
  background: #121212;
  /* border: 1px solid #b7bdc6; */
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>