<template>
    <v-container>
        <v-row>

            <v-col cols="12">
                <div class="d-flex align-center">
                    <v-toolbar class="transparent elevation-0">
                        <v-btn icon @click="$router.push('/account/security');">
                            <icon icon="material-symbols:arrow-back" width="24" height="24"></icon>
                        </v-btn>
                        <v-toolbar-title> {{ $t('security.security') }}</v-toolbar-title>
                    </v-toolbar>
                </div>
            </v-col>

            <v-col cols="12" md="8" lg="8">
                <v-sheet class="pa-5" style="border-radius: 10px;">
                    <h4 style="color:#FAFBFC">
                        {{ $t('security.accountConnections') }}
                    </h4>

                    <div class="d-flex align-center justify-space-between">
                        <div class="my-5 d-flex flex-column align-start">
                            <div><v-icon class="">fa-brands fa-google</v-icon> <span class="ml-2">{{
                                $t('security.googleAccount') }}</span>
                            </div>
                            <div class="mt-2">{{ $t('security.connected') }}</div>
                        </div>

                        <div>
                            {{ gmail.email }} <v-icon color="green">mdi-check-circle</v-icon> <br />
                        </div>
                    </div>
                </v-sheet>
            </v-col>

            <!-- 
            <v-col cols="12" xs="12" sm="8" lg="3">
                <v-sheet class="pa-5" color="#1F2339" style="border-radius: 10px; border: 1px solid #9493AC; ">

                    <div class="d-flex align-center mb-3">
                        <v-icon class="mr-3">fa-regular fa-lightbulb</v-icon>

                        <div style="color: #FAFBFC; font-size: 16px;">
                            <span>Check Account Activity</span>
                        </div>
                    </div>

                    <div style="padding-left: 2.1rem;">
                        <p style="color:#9493AC">You can check all activity on your account by clicking the link below.</p>

                        <a href="#" style="color: #13e5e3;">Account Activity</a>
                    </div>

                </v-sheet>
            </v-col> -->

            <v-col cols="12" md="8" lg="8">

                <v-sheet class="pa-5" style="border-radius: 10px;">

                    <h4 style="color:#FAFBFC">
                        {{ $t('security.mobileNumber') }}
                    </h4>

                    <div class="d-flex align-center justify-space-between">
                        <div class="my-5 d-flex flex-column align-start">
                            <div><span> {{ $t('security.mobileNumber') }}</span></div>
                            <div v-if="mobile.status === 'Verified'">
                                {{ $t('security.connected') }}
                            </div>

                            <v-btn v-else @click="openModal" small color="primary" class="custom-button-width"
                                style="color: #000;">
                                {{ mobile.status }}
                            </v-btn>
                        </div>

                        <div>
                            {{ mobile.email }} <v-icon color="green">mdi-check-circle</v-icon> <br />
                        </div>
                    </div>

                </v-sheet>

            </v-col>
        </v-row>

        <v-dialog :width="!$vuetify.breakpoint.sm && 520" v-model="modalConnectNumber" :fullscreen="$vuetify.breakpoint.sm"
            hide-overlay :transition="$vuetify.breakpoint.sm && 'bottom-transition'" scrollable>
            <v-card>
                <v-card-title class="mt-5 d-flex align-center justify-space-between">
                    <div>{{ $t('security.addMobileNumber') }}</div>
                    <v-btn icon dark @click="modalConnectNumber = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>

                    <v-form>
                        <v-text-field v-model="mobile_number" class="mt-5" dense placeholder="Mobile number"
                            outlined></v-text-field>

                        <v-btn @click="handleVerificationModal = true; sendVerification()" color="primary"
                            style="color: #000;" class="mt-2" large block>
                            {{ $t('dialog.confirm') }}
                        </v-btn>
                    </v-form>

                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog width="520" v-model="modalVerification" :fullscreen="$vuetify.breakpoint.sm" hide-overlay
            :transition="$vuetify.breakpoint.sm && 'bottom-transition'" scrollable>
            <v-card>

                <v-card-title class="mt-5 d-flex align-center justify-space-between">
                    <div>{{ $t('security.verification') }}</div>
                    <v-btn icon dark @click="modalVerification = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row justify="center">
                            <v-col cols="12">
                                <!-- <div class="d-flex align-center">
                                    <v-text-field class="mt-8" dense placeholder="Mobile number" outlined></v-text-field>
                                    <v-btn large class="ml-2">Send Verification</v-btn>
                                </div> -->

                                <v-text-field v-model="verification_code" class="mt-5" dense placeholder="Code"
                                    outlined></v-text-field>
                            </v-col>

                            <v-btn @click="bindNumber()" color="primary" style="color: #000;" class="m" large block>
                                {{ $t('dialog.confirm') }}
                            </v-btn>

                        </v-row>
                    </v-container>
                </v-card-text>

            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
export default {
    data() {
        return {
            gmail: [],
            apple: [],
            mobile: [],
            modalConnectNumber: false,
            modalVerification: false,
            mobile_number: '',
            verification_code: '',
        }
    },

    mounted() {
        this.fetchAccounts()
    },

    methods: {

        openModal() {
            this.modalConnectNumber = true;
        },

        handleVerificationModal() {
            this.modalConnectNumber = false;
            this.modalVerification = true;
        },

        fetchAccounts() {
            this.$axios.get('https://api.uccoin.co/api/v3/bind-accounts')
                .then(response => {
                    this.gmail = response.data.gmail
                    this.apple = response.data.apple
                    this.mobile = response.data.mobile
                })
        },

        sendVerification() {
            this.$axios.get('https://api.uccoin.co/api/v3/sendSMSCode', {
                params:
                {
                    type: 'REGISTER',
                    mobile_number: this.mobile_number,
                }
            })
                .then(response => {
                    this.$swal({
                        toast: true,
                        position: "top-end",
                        title: "Verification Sent!",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000, // milliseconds
                    });

                    this.modalVerification = true;
                })


        },

        bindNumber() {
            this.$axios.get('https://api.uccoin.co/api/v3/verify-mobile', {
                params: {
                    verification_code: this.verification_code,
                    mobile_number: this.mobile_number
                },

            }).then(response => {
                console.log(response.data.message)
                if (response.data.message == 'success') {
                    this.$swal({
                        toast: true,
                        position: "top-end",
                        title: "Verification Sent!",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000, // milliseconds
                    });

                    this.modalVerification()
                }
                else {
                    this.$swal({
                        toast: true,
                        position: "top-end",
                        title: "Verification Sent!",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000, // milliseconds
                    });
                }
            })
        }
    }
}
</script>