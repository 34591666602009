<template>
  <v-container>

    <div class="d-flex justify-center " :class="!$vuetify.breakpoint.xs ? 'flex-row' : 'flex-column text-center mt-10'"
      :style="!$vuetify.breakpoint.xs && 'padding: 120px 8px;'">
      <div>
        <h1 style="color: #fff;" :style="{
          fontSize: !$vuetify.breakpoint.xs ? '55px' : ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) ? '32px' : '',
          margin: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'auto' : '',
          maxWidth: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '500px' : '700px',
          textAlign: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'center' : ''
        }">
          {{ $t('landing.phrase_1') }}
        </h1>

        <div v-if="user === null" class="mt-10 d-flex"
          :class="$vuetify.breakpoint.xs && 'justify-center align-center text-center'">

          <v-row align="center" dense :justify="!$vuetify.breakpoint.lg && 'center'">
            <v-col cols=" 12" xs="12" sm="12" lg="8">
              <!-- if mobile  -->
              <v-btn v-if="$vuetify.breakpoint.xs" class="mb-10" large color="#fff"
                style=" width: 150px; color: #1C1D28 !important; font-weight: 700; text-transform: capitalize; border-radius: 20px;"
                to="/signin">
                {{ $t('landing.getStarted') }}
                <!-- Sign-in/Sign-up -->
              </v-btn>
            </v-col>

            <!-- if button is large screen -->
            <v-col v-if="!$vuetify.breakpoint.xs" cols="12">
              <v-btn large color="#ffffff"
                style="width: 150px; color: #1C1D28 !important; font-weight: 700; text-transform: capitalize; border-radius: 20px;"
                to="/signin">
                {{ $t('landing.getStarted') }}
                <!-- Sign-in/Sign-up -->
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
      <div>
        <v-img max-width="500" :src="require('@/assets/images/mobile.svg')" />
      </div>
    </div>

    <v-col cols="12" class="custom-margin" v-if="!$vuetify.breakpoint.xs">
      <main-home-download-app />
    </v-col>

    <v-col cols="12">

      <div class="text-center" :style="!$vuetify.breakpoint.xs && 'padding: 120px 8px;'">
        <h1 style="color: #fff;" :style="{
          fontSize: !$vuetify.breakpoint.xs ? '55px' : ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) ? '32px' : '',
          margin: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'auto' : '',
          maxWidth: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '500px' : '',
          textAlign: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'center' : ''
        }">
          {{ $t('landing.phrase_2') }}
        </h1>
      </div>

      <div class="py-10 text-center">
        <div v-if="!$vuetify.breakpoint.xs">
          <video class="third-pc-animation" autoplay="" loop="" playsinline="" width="936" height="253" muted=""
            style="visibility: visible;" src="https://www.okx.com/cdn/assets/files/2210/D47D930F643E7A00.webm"
            poster="https://www.okx.com/cdn/assets/imgs/2210/2763D233C494439D.jpg?x-oss-process=image/format,webp">
            <track kind="captions">
          </video>
        </div>

        <div v-else>
          <img class="third-mobile-animation" alt="With you every step of the wayFrom your first crypto trade to your first NFT purchase, you’ll have us to guide you through the process. No stupid questions. 
                No sleepless nights. Have confidence in your crypto." width="400" height="400"
            src="https://www.okx.com/cdn/assets/imgs/2210/602389EA3A7E31BD.gif">
        </div>
      </div>
    </v-col>

    <!-- news-->
    <div class="custom-margin">
      <v-row justify="center" align="center">

        <v-col xs="12" sm="8" md="10">

          <div :style="$vuetify.breakpoint.lg && 'padding: 120px 8px;'">

            <h1 style="color: #fff;" :style="{
              fontSize: !$vuetify.breakpoint.xs ? '55px' : ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) ? '32px' : '',
              margin: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'auto' : '',
              maxWidth: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '500px' : '',
              textAlign: 'center'
            }">
              {{ $t('landing.newsHeader') }}
            </h1>

            <v-progress-circular v-if="isLoading" indeterminate color="primary" class="my-10" style="
            top: 50%;
            bottom: 50%;
            left: 50%;
            transform: translate(-50%, -50%);">
            </v-progress-circular>

            <div v-else class="my-10">
              <v-row class="fill-height" align="center" v-if="!$vuetify.breakpoint.xs" justify="center">
                <v-col cols="4" xs="12" sm="10" md="6" lg="4" v-for="{ id, url, imageurl, title, body } in posts"
                  :key="id">

                  <v-hover v-slot:default="{ hover }">
                    <v-card :href="url" tag="a" target="_blank" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }"
                      style="border: 1px solid lightgray;">

                      <v-img height="300px" :src="imageurl" class="white--text align-end" :gradient="hover
                        ? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.8)'
                        : 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,1)'
                        ">
                        <div class="d-flex align-center justify-space-between pa-3">
                          <v-container>

                            <div>
                              <span style="color: #fff; font-size: 22px; font-weight: 700; line-height: 32px;">{{ title
                              }}</span>
                            </div>

                            <div>
                              <span class="mt-2"
                                style="font-size: 14px; display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                {{ body }}
                              </span>
                            </div>
                          </v-container>
                        </div>
                      </v-img>

                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </div>

          </div>

          <v-list-item v-if="$vuetify.breakpoint.xs" style="border-bottom: 1px solid #949ca3;"
            v-for="{ id, url, title, body } in posts" :key="id" :href="url" tag="a" target="_blank">
            <v-list-item-content>
              <v-list-item-title> <span style="font-weight: 700;"> {{ title }}</span> </v-list-item-title>
              <span class="mt-2"
                style="font-size: 14px; display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                {{ body }}</span>
            </v-list-item-content>
          </v-list-item>

        </v-col>
      </v-row>

    </div>

    <div class="custom-margin">
      <main-home-questions />
    </div>

  </v-container>
</template>

<script>
import axios from 'axios';
import TopCoins from './home/TopCoins.vue'
import CryptoJS from 'crypto-js';

import TextFieldComponent from '../ui/TextfieldComponent.vue'

import { fetchPowPairs } from '@/services/marketServices';
import vuetify from '@/plugins/vuetify';

export default {
  components: {
    TopCoins,
    TextFieldComponent,
  },

  data() {
    return {
      // powPairs: [],
      isLoading: false,
      posts: [],
    };
  },

  methods: {
    fetchNewsFeed() {
      this.isLoading = true
      axios.get('https://api.uccoin.co/api/v3/news')
        .then(response => {
          try {

            this.posts = response.data.newsfeed.Data.slice(0, 6).map(child => ({
              id: child.id,
              title: child.title,
              url: child.url,
              body: child.body,
              imageurl: child.imageurl
            }));

            console.log(this.posts)

          } catch (error) {
            console.log(error)
          } finally {
            this.isLoading = false
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  },

  mounted() {
    // this.pow()
    this.fetchNewsFeed()
  },

  created() {
    console.log(this.powPairs)
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
  },

  //Handle beforeRouteEnter with route hash
  beforeRouteEnter(to, from, next) {
    if (to.hash) {
      next((vm) => {
        vm.$nextTick(() => {
          const el = document.getElementById(to.hash.replace("#", ""));
          if (el) {
            //The top of the element will be aligned to the top of the viewport
            // const top = el.offsetTop;
            // console.log(top);
            // window.scrollTo({ top: top - 300, behavior: "smooth" });
            // el.scrollIntoView({ behavior: "smooth", block: "start" });
            //Scroll to the most bottom of the page
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            });
          }
        });
      });
    } else {
      next();
    }
  },

};
</script>

<style scoped>
.v-slide-group__prev {
  display: none !important;
}

a {
  color: rgb(209, 209, 209) !important;
}

.hero-section {
  /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
  /* height: 80vh; */
}

.full-height {
  height: 500px;
}

.custom-margin {
  margin-top: 100px;
}

/* #phones {
    max-height: 500px; 
  } */
</style>

