<template>
  <v-row justify="center">
    <v-col cols="12" xs="12" lg="10">
      <v-tabs v-model="tab" background-color="#00000000" bg-color="transparent" class="tabs-right">

        <v-tab value="one">{{ $t('wallet.overview') }}</v-tab>

        <v-tab-item class="pa-1">
          <OverviewTab @change-tab="changeTab" :isLoading="isLoading" :overviewTotal="overviewTotal"
            :totalPnlPercentage="totalPnlPercentage" :totalPnl="totalPnl" :goToWithdraw="goToWithdraw"
            :goToDeposit="goToDeposit" :goToHistory="goToHistory" :sheetStyle="sheetStyle" :spotPnl="spotPnl"
            :futurePnl="futurePnl" />
        </v-tab-item>

        <v-tab value="two">{{ $t('wallet.spot') }}</v-tab>
        <v-tab-item class="pa-1">
          <SpotTab @change-tab="changeTab" :isLoading="isLoading" :overviewTotal="overviewTotal" :spotItems="spotItems"
            :pnl="pnl" :spotPnl="spotPnl" :pnlPercentage="pnlPercentage" :goToWithdraw="goToWithdraw"
            :goToDeposit="goToDeposit" :goToHistory="goToHistory" :sheetStyle="sheetStyle" />
        </v-tab-item>

        <v-tab value="three">{{ $t('wallet.earn') }}</v-tab>
        <v-tab-item class="pa-1">
          <EarnTab :sheetStyle="sheetStyle" />
        </v-tab-item>

        <v-tab value="four">{{ $t('wallet.future') }}</v-tab>
        <v-tab-item class="pa-1">
          <FutureTab @change-tab="changeTab" :isLoading="isLoading" :overviewTotal="overviewTotal"
            :futureItems="futureItems" :futurePnlPercentage="futurePnlPercentage" :futurePnl="futurePnl"
            :goToWithdraw="goToWithdraw" :goToDeposit="goToDeposit" :goToHistory="goToHistory" :sheetStyle="sheetStyle" />
        </v-tab-item>

        <v-tab value="five">{{ $t('wallet.web3') }}</v-tab>
        <v-tab-item class="pa-1">
          <Web3Tab @change-tab="changeTab" :isLoading="isLoading" :overviewTotal="overviewTotal"
            :futureItems="futureItems" :futurePnlPercentage="futurePnlPercentage" :futurePnl="futurePnl"
            :goToWithdraw="goToWithdraw" :goToDeposit="goToDeposit" :goToHistory="goToHistory" :sheetStyle="sheetStyle" />
        </v-tab-item>


      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>

import SidebarComponent from '@/components/SidebarComponent.vue';

import OverviewTab from './Tabs/OverviewTab.vue'
import SpotTab from './Tabs/SpotTab.vue'
import EarnTab from './Tabs/EarnTab.vue'
import FutureTab from './Tabs/FutureTab.vue'
import Web3Tab from './Tabs/Web3Tab.vue'

import { fetchOverview, fetchSpot, fetchFuture } from '../../../../services/walletServices'
import { getCoinPnl, getWalletSingleCoin } from '../../../../services/CoinServices'
import axios from 'axios';
import Web3 from 'web3';

import WalleConnect from '@walletconnect/client';
import QRCodeModal from '@walletconnect/qrcode-modal'

export default {

  components: {
    // SidebarComponent,
    OverviewTab,
    SpotTab,
    EarnTab,
    FutureTab,
    Web3Tab,
  },

  data() {
    return {
      ovTimer: '',
      spotTimer: '',
      earnTimer: '',
      futureTimer:'',
      web3Timer: '',

      pnlData: {},
      tab: "one",
      isloading: false,
      overviewLoading: true,
      wallet: {},
      pieChartData: {},
      overviewPieChartData: {},
      walletCoins: [],
      walletBalances: [],
      futureWalletCoins: {},
      overviewTotal: [],
      balance: 0,
      // coin-spot-pnl
      spotPnl: 0,
      pnlPercentage: 0,
      futurePnl: 0,
      futurePnlPercentage: 0,
      totalPnl: 0,
      totalPnlPercentage: 0,
      earn: [],

      pnl: {
        value: 0,
        percentage: 0,
      },
      spotItems: [],
      futureItems: [],
      miningItems: [],

      headerEarn: [
        {
          value: "name",
          text: "Name",
          align: "start",
          sortable: true,
        },

        {
          value: "apr",
          text: "APR",
          align: "start",
          sortable: true,
        },
        {
          value: "type",
          text: "Type",
          align: "start",
          sortable: true,
        },

        {
          value: "balance",
          text: "Balance",
          align: "start",
          sortable: true,
        },
        {
          value: "usdt_value",
          text: "In USDT",
          align: "start",
          sortable: true,
        },
      ],

      headerMiner: [
        {
          value: "name",
          text: "Name",
          align: "start",
          sortable: true,
        },
        {
          value: "earnings",
          text: "Name",
          align: "start",
          sortable: true,
        },

        {
          value: "running",
          text: "Days Running",
          align: "start",
          sortable: true,
        },

        {
          value: "actions",
          text: "Actions",
          align: "end",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    tab: {
      handler: function (val) {
        this.$store.commit("setWalletView", val);
      },
    },
  },
  methods: {
    myEarn() {
      axios.get('https://api.uccoin.co/api/v3/stake')
        .then(response => {
          this.earn = response.data.stake
        })
    },
    getSpotPnl() {
      // console.log('wowowowow')
      axios.get('https://api.uccoin.co/api/v3/coin-spot-pnl')
        .then(response => {
          this.spotPnl = response.data.spotProfit
          this.pnlPercentage = response.data.spotPercentage

          axios.get('https://api.uccoin.co/api/v3/coin-future-pnl')
            .then(response2 => {
              this.futurePnl = response2.data.spotProfit
              this.futurePnlPercentage = response2.data.spotPercentage
            })

          this.totalPnl = this.spotPnl + this.futurePnl

          this.totalPnlPercentage = (this.pnlPercentage + this.futurePnlPercentage) / 2
        })

    },

    getFuturePnl() {
      // console.log('wowowowow')

    },

    async fetchCoinPnl() {
      try {
        const coinPnl = await getCoinPnl();
        if (coinPnl) {
          this.pnlData = coinPnl;
          // console.log(coinPnl);
        } else {
          console.log("No coin P&L data available.");
        }
      } catch (error) {
        console.error("Error fetching coin P&L:", error);
        // Handle error (e.g., show an error message to the user)
      } finally {
        // Perform any cleanup operations if needed
      }
    },

    async fetchWalletSingleCoin() {
      // const singleCoin = await getWalletSingleCoin(this.$store.selectedCoin)
      // console.log(singleCoin)
    },

    async fetchOverviewData() {
      try {
        this.isLoading = true;
        const walletOverview = await fetchOverview();
        // console.log(walletOverview)
        this.overviewTotal = walletOverview
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        this.isLoading = false
      }
    },

    async fetchSpotData() {
      try {
        this.isLoading = true;
        const walletSpot = await fetchSpot();

        this.isLoading = false
        this.spotItems = walletSpot;
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        this.isLoading = false
      }
    },

    async fetchFutureData() {
      try {
        let walletFuture = JSON.parse(localStorage.getItem('walletFuture'));
        if (!walletFuture) {
          walletFuture = await fetchFuture();
          localStorage.setItem('walletFuture', JSON.stringify(walletFuture));
        }

        this.futureItems = walletFuture;
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    },

    fetchMinings() {
      axios.get(' https://api.uccoin.co/api/v3/minings')
        .then(response => {
          this.miningItems = response.data.miners
        })
    },

    metamaskMobileConnect() {
      const connector = WalleConnect({
        bridge: "https://bridge.walletconnect.org",
        QRCodeModal: QRCodeModal
      });

      if (!connector.connected) {
        connector.createSession();
      }

      connector.on("connect", (error, payload) => {
        if (error) {
          throw error;
        }

        // const 
        const { accounts, chainId } = payload.params[0];
        console.log("Connected Accounts:", accounts);
        console.log("Connected Chain:", chainId);
      })
    },

    goToWithdraw() {
      this.$router.push({ path: `/wallet/withdraw`, });
    },

    goToDeposit() {
      this.$router.push({ path: `/wallet/deposit` });
    },

    goToHistory() {
      this.$router.push({ path: `/history` });
    },

    goToStake() {
      this.$router.push({ path: `/wallet/stake` });
    },

    goToMiner() {
      this.$router.push({ path: `/wallet/miner` });
    },

    goToLive() {
      this.$router.push({ path: `/wallet/live` });
    },

    changeTab(tab) {
      this.tab = tab;
      // console.log(tab)
    },

  },

  computed: {
    sheetStyle() {
      if (this.$vuetify.breakpoint.xs) {
        // If the screen size is xs, return an empty object to remove the style
        return {};
      } else {
        // If the screen size is not xs, return the default style
        return {
          borderRadius: '2px',
          // border: '1px solid #b7bdc6 '
        };
      }
    }

  },



  mounted() {
    // this.fetchCoinPnl()
    this.fetchOverviewData()
    this.fetchSpotData()
    this.fetchFutureData()
    this.getSpotPnl()
    // this.getFuturePnl()
    this.myEarn()

    this.ovTimer = setInterval(this.fetchOverviewData, 5000)
    this.spotTimer = setInterval(this.fetchSpotData, 5000)
    this.futureTimer = setInterval(this.futureTimer, 5000)
    this.earnTimer = setInterval(this.myEarn, 5000)


    // this.overviewPnl()



    // console.log(this.futurePnl)

  }, 
  beforeDestroy()
  {
    clearInterval(this.ovTimer)
    clearInterval(this.spotTimer)
    clearInterval(this.futureTimer)
    clearInterval(his.earnTimer)
  }
};
</script> 

<style scoped>
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}

.full-screen-container {
  height: 90vh;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
