<template>
    <v-row class="mt-2 mb-10">

        <v-col cols="12" sm="12" lg="12">
            <h4>{{ $t('markets.allHeader') }}</h4>
            <p style="color:#FAFBFC;">{{ $t('markets.allSubtitle') }}</p>
        </v-col>

        <v-col v-if="$vuetify.breakpoint.xs" align="start" cols="12" xs="12" md="8" lg="6">
            <SearchBarComponent :search.sync="search" placeholder="Search Coin" />
        </v-col>

        <v-col v-if="!$vuetify.breakpoint.xs" cols="12">

            <!-- <v-text-field outlined dense placeholder="Search Coin" append-icon="mdi-magnify"></v-text-field> -->
            <v-data-table :loading="isLoading" :headers="headers" :items="filteredData" :items-per-page="30"
                class="transparent" @click:row="rowClicked($event)">

                <template v-slot:top>
                    <v-toolbar flat color="transparent">
                        <v-row>
                            <v-col cols="12" xs="12" md="6" lg="3">
                                <SearchBarComponent :search.sync="search" placeholder="Search Coin" />
                            </v-col>
                        </v-row>
                    </v-toolbar>
                </template>

                <template v-slot:item.name="{ item }">
                    <div class="d-flex align-center">

                        <div class="mr-2">
                            <v-icon v-if="item.favorite == ''" @click="addToFavorites(item)" color="grey"
                                style="font-size:15px;">mdi-star</v-icon>
                            <v-icon v-else @click="removeFave(item)" color="yellow"
                                style="font-size:15px;">mdi-star</v-icon>
                            <!-- <v-icon v-else-if="item.favorite == 0" @click="addToFavorites(item)" color="grey"
                                style="font-size:15px;margin-top:5px">mdi-star</v-icon> -->
                        </div>

                        <div class="mr-2">
                            <v-avatar color="white" size="30">
                                <v-img :src="item.image"></v-img>
                            </v-avatar>
                        </div>

                        <div class="mr-2 d-flex align-center">
                            <!-- <span class="mr-2" style="font-size:16px;">{{ item.coin_pair_name }}</span><br /> -->
                            <span class="subtitle-table">{{ (item.coin_pair_name).replace('USDT', '') }}</span>
                            <!-- <span style="font-size: 14px;"> {{ item.date }}</span><br /> -->
                        </div>
                    </div>
                </template>

                <template v-slot:item.close="{ item }">

                    <span class="subtitle-table ">
                        {{ formattedPrice(item.close) }}
                    </span><br />


                    <!-- <span style="font-size:10px;color:grey">{{ (item.coin_pair_name).replace('USDT', '')}}</span> -->
                    <!-- <span> {{ item.date }}</span><br/> -->
                </template>

                <template v-slot:item.increase_in_value="{ item }">

                    <span class="subtitle-table" v-if="item.increase_in_value > 0" style="color:#0ECB81">
                        {{ parseFloat(item.increase_in_value).toFixed(4) }}%
                        <!-- + {{ (item.increase_in_value.toFixed(4)) }} -->
                    </span>

                    <span class="subtitle-table" v-if="item.increase_in_value < 0" style="color:#F6465D">
                        {{ parseFloat(item.increase_in_value).toFixed(4) }}%
                        <!-- {{ (item.increase_in_value.toFixed(4)) }} -->
                    </span>
                </template>

                <!-- <template v-slot:item.volume="{ item }">
                    <span class="subtitle-table">{{ formattedVolume(item.volume) }}</span><br />
                </template> -->

            </v-data-table>
        </v-col>

        <!-- mobile view -->
        <v-col v-else cols="12">
            <!-- <SearchBarComponent :search.sync="search" /> -->

            <v-row v-if="isLoading">
                <div class="text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
            </v-row>

            <div v-if="filteredData.length <= 0 && !isLoading">
                <div class="d-flex align-center justify-center mt-10" style="height: 100%">
                    <div class="d-flex flex-column align-center justify-center">
                        <v-icon x-large color="#b7bdc6">
                            mdi mdi-note-search-outline
                        </v-icon>
                        <span style="font-size: 12px; color: #b7bdc6">No Records Found</span>

                        <div class="mt-3">
                            <ButtonComponent style="color: #1C1D28 ;" color="primary" label="Subscribe Now" />
                        </div>

                    </div>
                </div>
            </div>

            <v-row v-if="!isLoading" class="mt-3"
                v-for="{ image, coin_pair_name, favorite, close, increase_in_value, volume } in filteredData"
                @click="mobileRowClicked(coin_pair_name)" style="border-bottom: 1px solid #929292;">
                <v-col sm="6" align="start">
                    <div class="d-flex flex-column">

                        <div class=" d-flex">
                            <div class="mr-1">
                                <v-icon v-if="favorite == ''" @click="addToFavorites(item)" color="grey"
                                    style="font-size:15px; margin-top:1px">mdi-star</v-icon>
                                <v-icon v-else @click="removeFave(item)" color="yellow"
                                    style="font-size:15px; margin-top:1px">mdi-star</v-icon>
                            </div>

                            <v-avatar color="white" size="30">
                                <v-img :src="image"></v-img>
                            </v-avatar>
                        </div>

                        {{ coin_pair_name }}
                    </div>
                </v-col>

                <v-col sm="6" align="end">
                    {{ formattedPrice(close) }}
                    <br />
                    <small>

                        <span v-if="increase_in_value > 0" style="color:#0ECB81">
                            {{ parseFloat(increase_in_value).toFixed(4) }}%
                            <!-- + {{ (item.increase_in_value.toFixed(4)) }} -->
                        </span>

                        <span v-if="increase_in_value < 0" style="color:#F6465D">
                            {{ parseFloat(increase_in_value).toFixed(4) }}%
                            <!-- {{ (item.increase_in_value.toFixed(4)) }} -->
                        </span>

                    </small>
                </v-col>
                <!-- 
                <v-col sm="4" align="end">
                    <span>{{ formattedVolume(volume) }}</span><br />
                </v-col> -->

            </v-row>
        </v-col>

    </v-row>
</template>

<script>
import numeral from 'numeral';
import SearchBarComponent from '@/components/ui/SearchBarComponent.vue';

import { fetchAllPairs } from '../../../../services/marketServices'
import vuetify from '@/plugins/vuetify';

export default {

    data: () => ({
        search: "",
        allCoins: [],
        isLoading: false,
        headers: [
            {
                value: "name",
                text: "Name",
                align: "start",
                sortable: true,
            },
            {
                value: "close",
                text: "Price",
                sortable: true,
                align: "end",
            },
            {
                value: "increase_in_value",
                text: "24h Change",
                sortable: true,
                align: "end",
            },
            // {
            //     value: "volume",
            //     text: "Volume",
            //     sortable: true,
            //     align: "end",
            // },
        ],
    }),

    components: {
        SearchBarComponent,
    },

    computed: {
        client() {
            return this.$store.getters.client;
        },

        filteredData() {
            if (!this.search) {
                return this.allCoins;
            }
            const searchTerm = this.search.toLowerCase();
            return this.allCoins.filter(item => item.coin_pair_name.toLowerCase().includes(searchTerm));
        },

        formattedPrice() {
            return (price) => numeral(price).format('$0,0.00'); // Format price as currency
        },

        formattedVolume() {
            return (volume) => numeral(volume).format('0,a'); // Format volume as whole number
        },

        formattedIncInVolume() {
            return (value) => numeral(value).format('00.00%');
        },

        client() {
            return this.$store.getters.client;
        }
    },

    methods: {

        rowClicked(value) {
            const name = (value.coin_pair_name).replace('USDT', '')
            // console.log(name)
            this.$store.state.selectedCoin = name;
            this.$router.push(`/trades/trade-chart/${name}`);
        },

        addToFavorites(item) {
            console.log(item)
            axios.get('https://api.uccoin.co/api/v3/add-favorite', {
                params: {
                    coin_id: item.coin_id,
                }
            })
                .then(response => {
                    this.fetchAllPairsData()
                    this.fetchPowPairsData()
                    this.fetchPosPairsData()
                    this.fetchInnoPairsData()
                })
        },

        mobileRowClicked(value) {
            const name = (value).replace('USDT', '')
            // console.log(name)
            this.$store.state.selectedCoin = name;
            this.$router.push(`/trades/trade-chart/${name}`);
        },


        //Fetch All Pairs
        async fetchAllPairsData() {
            this.isLoading = true;
            try {
                this.allCoins = await fetchAllPairs(this.client.id);
            } catch (error) {
                console.log(error);
            } finally {
                this.isLoading = false;
            }
        }

    },
    mounted() {
        this.fetchAllPairsData()
    }
}
</script>

<style scoped>
.subtitle-table {
    font-size: 16px;
    /* font-weight: 500; */
    line-height: 24px
}
</style>