<template>
  <v-row>
    <v-col v-if="!$vuetify.breakpoint.xs" class="mt-5" cols="12">
      <v-data-table :loading="isLoading" :headers="headers" :items="tradeHistories" :items-per-page="5"
        class="transparent elevation-1 pa-5">

        <template v-slot:item.type="{ item }">
          <v-chip small v-if="item.delegate_type == 'BUY'" color="#0ECB81">{{ $t('trade.buy') }}</v-chip>
          <v-chip small v-if="item.delegate_type == 'SELL'" color="#CA3F64">{{ $t('trade.sell') }}</v-chip>
        </template>

        <template v-slot:item.amount="{ item }">
          <span v-if="item.delegate_type == 'BUY'">{{ Number(item.number_of_order) / Number(item.final_price)
          }}</span>
          <span v-if="item.delegate_type == 'SELL'">{{ Number(item.number_of_order) * Number(item.final_price)
          }}</span>
        </template>

        <template v-slot:item.total="{ item }">
          <span>{{ Number(item.number_of_order) * Number(item.final_price) }}</span>
        </template>

      </v-data-table>
    </v-col>

    <v-col v-else class="mt-5" cols="12">

      <div v-if="isLoading" class="text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>

      <div v-else>
        <div v-if="tradeHistories.length <= 0">
          <div class="d-flex align-center justify-center pa-10" style="height: 100%">
            <div class="d-flex flex-column align-center justify-center">
              <v-icon x-large color="#b7bdc6">
                mdi mdi-note-search-outline
              </v-icon>
              <span style="font-size: 12px; color: #b7bdc6"> {{ $t('trade.noRecords') }}</span>
            </div>
          </div>
        </div>

        <div v-else>
          <v-row v-for="{ delegate_type, number_of_order, coin_pair, final_price, total } in tradeHistories">
            <v-col cols="12">
              <div class="d-flex justify-space-between">
                <span class="value">
                  {{ coin_pair.pair_name }}
                </span>

                <span class="value">
                  {{ final_price }}
                </span>
              </div>

              <div class="d-flex justify-space-between">
                <span>{{ $t('trade.price') }}</span>
                <span v-if="delegate_type == 'BUY'">{{ Number(number_of_order) / Number(final_price)
                }}</span>
                <span v-if="delegate_type == 'SELL'">{{ Number(number_of_order) * Number(final_price)
                }}</span>
              </div>

              <div class="d-flex justify-space-between">
                <span>{{ $t('trade.total') }}</span>
                <span>{{ Number(number_of_order) * Number(final_price) }}</span>
              </div>

              <div class="d-flex justify-space-between">
                <span v-if="state == 'APPROVED'" style="color:#0ECB81 ">{{ $t('trade.filled') }}</span>
                <span v-if="state == 'DISSAPROVED'" style="color:#CA3F64 ">{{ $t('trade.cancelled') }}</span>

                <v-chip small v-if="delegate_type == 'BUY'" color="#0ECB81">{{ $t('trade.buy') }}</v-chip>
                <v-chip small v-if="delegate_type == 'SELL'" color="#CA3F64">{{ $t('trade.sell') }}</v-chip>
              </div>

            </v-col>
            <v-divider color="#b7bdc6 "></v-divider>
          </v-row>
        </div>

      </div>

    </v-col>

  </v-row>
</template>

<script>

import { gettradeHistories } from '@/services/Trades';

export default {
  data() {
    return {
      isLoading: false,
      tradeHistories: [],
      headers: [
        {
          value: "coin_pair.pair_name",
          text: "Token",
          align: "start",
          sortable: true,
        },
        {
          value: "type",
          text: "Type",
          sortable: true,
          align: "center",
        },

        {
          value: "final_price",
          text: "Price (USDT)",
          sortable: true,
          align: "end",
        },
        {
          value: "amount",
          text: "Filled",
          sortable: true,
          align: "end",
        },
        {
          value: "total",
          text: "Total (USDT)",
          sortable: true,
          align: "end",
        },

        // {
        //   value: "number_of_order",
        //   text: "Number of Orders",
        //   sortable: true,
        //   align: "start",
        // },
      ],
    }
  },
  methods: {
    async fetchOrderHistories() {
      try {
        this.isLoading = true; // Set loading state to true before making the request
        const response = await this.$axios.get('https://api.uccoin.co/api/v3/trades/order-histories');
        this.tradeHistories = response.data.orders;
      } catch (error) {
        console.error('Error fetching order histories:', error);
      } finally {
        this.isLoading = false; // Set loading state to false after the request is complete (whether it succeeds or fails)
      }
    },
  },

  mounted() {
    this.fetchOrderHistories()
  }
}
</script>