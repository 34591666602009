<template>
    <v-row v-if="!loading">
        <v-col cols="12" xs="12" sm="6" md="4" lg="3" v-for="coin in filteredCoins">
            <main-earn-coin-simple :key="coin.id" :name="coin.name" :image="coin.image" :apr="coin.apr" :earn="coin.earn" />
        </v-col>
    </v-row>

    <v-row v-else class="mt-4">
        <div class="text-center">
            <v-progress-circular color="primary" indeterminate class="mt-5"></v-progress-circular>
        </div>
    </v-row>

    <!-- 
        <v-container v-if="!loading" class="mt-4">
            <v-row>
                <v-col cols="12" xs="12" sm="6" md="4" lg="3" v-for="coin in filteredCoins">
                    <main-earn-coin-simple :key="coin.id" :name="coin.name" :image="coin.image" :apr="coin.apr"
                        :earn="coin.earn" />
                </v-col>
            </v-row>
        </v-container>
        -->
</template>
<script>
export default {
    props: {
        search: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        coins: [],
    }),
    computed: {
        filteredCoins() {

            // Filter coins based on availability and search value
            return this.coins.filter((coin) => {
                return coin.apr !== "Not available" &&
                    coin.name.toLowerCase().includes(this.search.toLowerCase());
            });

            // //Filter first if the coin is available
            // let coins = this.data.filter((coin) => {
            //     return coin.apr != "Not available";
            // });
            // // Filter coins based on search value
            // coins = coins.filter((coin) => {
            //     return coin.name
            //         .toLowerCase()
            //         .includes(this.search.toLowerCase());
            // });
            // return coins;
        },
    },
    methods: {
        async fetchEarn() {
            this.loading = true;
            try {
                const response = await this.$axios.get(
                    "https://api.uccoin.co/api/v3/earn/view"
                );
                this.loading = false;
                this.coins = response.data.earn;
            } catch (error) {
                console.log(error);
                return [];
            }
        },
    },
    mounted() {
        this.fetchEarn();
    },
};
</script>
<style>
.v-tab {
    min-width: 60px !important;
}

.theme--dark.v-tabs-items {
    background-color: transparent !important;
}

.market-container {
    width: 100vw !important;
    border-radius: 20px 20px 0 0 !important;
    background-color: #1e1e1e !important;
}
</style>
