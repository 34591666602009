<template>
    <v-row class="mt-2">

        <v-col cols="12">
            <v-sheet :color="!$vuetify.breakpoint.xs ? '#272727' : '#00000000'"
                :class="!$vuetify.breakpoint.xs ? 'mx-auto pa-8 mt-1' : ''" :style="sheetStyle">
                <v-row>

                    <!-- Loading indicator -->
                    <div v-if="isLoading" class="text-center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>

                    <v-col v-else cols="12">
                        <div class="d-flex justify-space-between">

                            <div class="d-flex flex-column align-start">

                                <div class="d-flex align-center">
                                    <span style="font-size: 14px;">Web3 Wallet Address {{ accounts }}</span>

                                    <v-btn icon x-small class="ml-2">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </div>

                                <div class="mt-2 d-flex align-center">
                                    <h2 style="font-size: 34px; font-weight: 500;">
                                        {{ balanceInEther }}
                                    </h2>
                                    <small class="ml-2">ETH</small>
                                </div>
                            </div>


                            <!-- testing -->
                            <div v-if="!$vuetify.breakpoint.xs">

                                <v-btn v-if="accounts === null || accounts === ''" @click="walletModal = true"
                                    :ripple="false" class="mr-3"
                                    style="text-transform: capitalize; font-size: 14px; color: #000;" color="primary">
                                    {{ $t('wallet.connectToWallet') }}
                                </v-btn>

                                <!-- Deposit Button -->
                                <!-- <v-btn :ripple="false" class="mr-3"
                                    style="width: 116px; text-transform: capitalize; font-size: 14px;" color="#5E6673">
                                    Deposit(Coming Soon)
                                </v-btn> -->

                                <v-btn @click="goToHistory()" :ripple="false"
                                    style="width: 116px; text-transform: capitalize; font-size: 14px;" color="#5E6673">
                                    {{ $t('wallet.history') }}
                                </v-btn>
                            </div>

                        </div>
                    </v-col>

                    <v-col v-if="$vuetify.breakpoint.xs" cols="12" align="start"
                        style="border-bottom: 0.1px solid #b7bdc6;">
                        <div class="mb-3 d-flex align-center justify-space-between">

                            <!-- <div>
                                <v-btn @click="metamaskMobileConnect()" :ripple="false" class="mr-3"
                                    style="text-transform: capitalize; font-size: 14px; color: #000;" color="primary">
                                    Connect to wallet
                                </v-btn>
                            </div> -->

                            <div>
                                <v-btn block @click="goToHistory()" :ripple="false" color="#5E6673"
                                    style="text-transform: capitalize; font-size: 14px; letter-spacing: 0; border-radius: 8px;">
                                    {{ $t('wallet.history') }}
                                </v-btn>
                            </div>


                        </div>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-col>

        <v-col cols="12">
            <v-divider />
        </v-col>

        <v-dialog v-model="walletModal" width="350">
            <v-card>
                <v-card-title>
                    <span style="font-size: 24px; font-weight: 500;">{{ $t('wallet.connectToWallet')
                    }}</span>
                </v-card-title>

                <v-card-text>
                    <div class="d-flex align-center justify-space-between">
                        <div class="d-flex align-center">
                            <v-avatar size="30">
                                <v-img :src="require('../../../../../assets/images/metamask-seeklogo.svg')">
                                </v-img>
                            </v-avatar>
                            <span class="ml-2">{{ $t('wallet.metaMask') }} </span>
                        </div>

                        <!-- <div>
                            <v-btn block @click="connectWallet('metamask')" :ripple="false" color="primary"
                            style="text-transform: capitalize; font-size: 14px; color: #1C1D28; letter-spacing: 0; border-radius: 8px;"
                            :disable="accounts">
                            Connect
                            </v-btn>
                            </div> -->

                    </div>

                    <!-- <div class="mt-5 d-flex align-center justify-space-between">
                        <div class="d-flex align-center">
                            <v-avatar color="white" size="30">
                                <v-img :src="require('../../../../../assets/images/walletconnect-seeklogo.svg')">
                                </v-img>
                            </v-avatar>

                            <span class="ml-2"> WalletConnect </span>

                        </div>
                    </div> -->
                </v-card-text>

            </v-card>
        </v-dialog>

    </v-row>
</template>

<script>
import SearchBarComponent from '@/components/ui/SearchBarComponent.vue';
import Web3 from 'web3'

export default {

    components: {
        SearchBarComponent,
    },

    data() {
        return {
            search: "",
            data: "",
            web3: null,
            accounts: null,
            balanceInEther: '',
            walletModal: false,
            headers: [
                {
                    value: "name",
                    text: "Name",
                    align: "start",
                    sortable: true,
                },
                {
                    value: "balance",
                    text: "Balance",
                    sortable: true,
                    align: "end",
                },
                {
                    value: "actions",
                    text: "Actions",
                    sortable: true,
                    align: "end",
                }
            ],
        }
    },

    props: {
        spotItems: Array,
        overviewTotal: Object | Array, // Specify the type of the prop
        goToWithdraw: Function,
        goToDeposit: Function,
        goToHistory: Function,
        pnlPercentage: Number,
        spotPnl: Number,
        pnl: Number,
        sheetStyle: [Function, Object],
        isLoading: Boolean,
    },


    mounted() {

        if (this.accounts == null) {
            this.connectWallet('metamask')
            this.accounts = 'Please Connect to Wallet'
            this.balanceInEther = 0
        }
        else {
            this.connectWallet(this.client.user.provider)
        }
    },

    computed: {
        user() {
            return this.$store.getters.user;
        },
    },

    methods: {

        handleOnTradeClick(value) {
            const name = (value);
            this.$store.state.selectedCoin = name;
            this.$router.push(`/trades/trade-chart/${name}`)
        },

        handleOnConvertClick() {
            this.$router.push(`/wallet/convert`)
        },

        handleOnTransferClick(value) {
            this.$router.push(`/wallet/transfer`)
        },

        handleOnViewClick(value) {
            const name = (value);
            this.$store.state.selectedCoin = name;
            this.$router.push(`/wallet/${name}`)
        },

        rowClicked(value) {
            const name = (value.name);
            this.$store.state.selectedCoin = name;
            this.$router.push(`/wallet/${name}`);
        },

        mobileRowClicked(value) {
            const name = (value)
            this.$store.state.selectedCoin = name;
            this.$router.push(`/wallet/${name}`);
        },

        async connectWallet(provider) {
            if (provider === 'metamask') {
                if (window.ethereum) {
                    try {
                        // Request account access
                        await window.ethereum.request({ method: "eth_requestAccounts" });
                        this.web3 = new Web3(window.ethereum);
                        const userAccount = await this.web3.eth.getAccounts();
                        this.accounts = userAccount[0]
                        const balance = await this.web3.eth.getBalance(userAccount[0]);
                        const etherBalance = this.web3.utils.fromWei(balance, 'ether');
                        this.balanceInEther = etherBalance
                        await this.bindWeb3(provider)

                    } catch (error) {
                        // Handle error
                        console.error('Error requesting account access:', error);
                        throw error;
                    }
                } else {
                    // MetaMask not found, prompt user to install
                    console.error('MetaMask not found');
                    throw new Error('MetaMask not found');
                }
            } else if (provider === 'walletconnect') {
                // Initialize WalletConnect provider and connect
                // Implement WalletConnect initialization and connection here
            } else {
                // Unsupported provider
                console.error('Unsupported provider');
                throw new Error('Unsupported provider');
            }
        },

        bindWeb3(provider) {
            // console.log(this.client.user.user_unique_id)
            if (this.accounts == 'Please Connect to Wallet') {
                this.$axios.get('https://api.uccoin.co/api/v3/web3-connect', {
                    params: {
                        provider: 'metamask',
                        uid: this.client.user.user_unique_id,
                        web3_wallet: this.accounts
                    }
                }).then(response => {
                    console.log(response)
                })
            }


        },

    },

    computed: {
        filteredData() {
            if (!this.search) {
                return this.spotItems;
            }
            const searchTerm = this.search.toLowerCase();
            return this.spotItems.filter(item => item.name.toLowerCase().includes(searchTerm));
        },

        client() {
            return this.$store.getters.client;
        },
    }

}
</script>

<style>
/* .subtitle-table {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px
} */
</style>