<template>
  <v-row justify="center">
    <v-col xs="12" sm="10">
      <v-container>
        <v-row>
          
          <v-col cols="12" xs="12" lg="10" xl="10">
            <div class="d-flex align-center">
              <v-toolbar class="transparent elevation-0">
                <v-btn icon @click="$router.push('/wallet');">
                  <icon icon="material-symbols:arrow-back" width="24" height="24"></icon>
                </v-btn>
                <v-toolbar-title> Wallet</v-toolbar-title>
              </v-toolbar>
            </div>
          </v-col>

          <v-form ref="withdrawForm" lazy-loading>
            <v-col cols="12" xs="12" sm="8" md="6" lg="4" class="mt-3">

              <h4>Withdrawals</h4>

              <div>
                <div class="d-flex align-center justify-space-between">
                  <label style="font-size: 14px; color: #b7bdc6 ">Address</label>
                </div>
                <v-text-field class=" mt-2" @input="checkAddress()" v-model="address" outlined placeholder="Wallet Address" dense rows="2"
                  append-icon="mdi-qrcode" clickable @click:append="openQRScanning()" :rules="rules.required" />
                  <span v-if="addressMessage != ''" style="color:red;font-size:11px">{{addressMessage}}</span>
              </div>

              <div>
                <label style="font-size: 14px; color: #b7bdc6">Coin</label>
                <v-select class=" mt-2" v-model="selectCoin" outlined dense :menu-props="{ bottom: true, offsetY: true }"
                  :items="coins" @input="selectedCoin()" placeholder="Select coin" item-text="name" item-value="value">
                  <template v-slot:selection="{ item, index }">
                    <v-avatar size="30" class="mr-2">
                      <v-img :src="item.image" />
                    </v-avatar>
                    {{ item.value }}
                    <!-- {{ item.value }} -->
                  </template>

                  <template v-slot:item="{ item }">
                    <v-row cols="12">
                      <v-col cols="12">
                        <v-avatar size="30">
                          <v-img :src="item.image" />
                        </v-avatar>
                        {{ item.value }}
                      </v-col>
                    </v-row>
                    <v-col cols="6" class="text-end">
                      Spot
                    </v-col>
                  </template>

                </v-select>
              </div>

              <div>
                <label style="font-size: 14px; color: #b7bdc6">Network</label>
                <v-select  v-if="selectCoin == 'USDT' || selectCoin == 'USDC'" :items="walletTypes" class="mt-2"
                  v-model="selectedType" :menu-props="{ bottom: true, offsetY: true }" outlined dense
                  @input="selectType(selectedType), checkAddress()" item-text="name" item-value="value">
                  <template v-slot:selection="{ item }">
                    <span style="color:grey"> {{ item.subname }}</span> <br />
                  </template>
                  <template v-slot:item="{ item }">
                    <v-row>
                      <v-col cols="5">
                        <span>{{ item.value }}</span> <br />
                        <span style="color:grey">{{ item.subname }}</span>
                      </v-col>
                      <v-col cols="7" class="text-end">
                        <span>Arrival Time {{ item.arrival }}</span> <br />
                        <span>Fee {{ item.gasFee }}</span>
                      </v-col>
                    </v-row>
                  </template>
                </v-select>

                <v-select v-else-if="selectCoin == 'ETH'" :items="ethWalletTypes" class="mt-2" v-model="selectedType"
                  :menu-props="{ bottom: true, offsetY: true }" outlined dense @input="selectType(selectedType), checkAddress()"
                  item-text="name" item-value="value">
                  <template v-slot:selection="{ item }">
                    <span style="color:grey"> {{ item.subname }}</span> <br />
                  </template>
                  <template v-slot:item="{ item }">
                    <v-row>
                      <v-col cols="5">
                        <span>{{ item.value }}</span> <br />
                        <span style="color:grey">{{ item.subname }}</span>
                      </v-col>
                      <v-col cols="7" class="text-end">
                        <span>Arrival Time {{ item.arrival }}</span> <br />
                        <span>Fee {{ item.gasFee }}</span>
                      </v-col>
                    </v-row>
                  </template>
                </v-select>

                <v-select v-else-if="selectCoin == 'BTC'" :items="btcWalletType" class="mt-2" v-model="selectedType"
                  :menu-props="{ bottom: true, offsetY: true }" outlined dense @input="selectType(selectedType), checkAddress()"
                  item-text="name" item-value="value">
                  <template v-slot:selection="{ item }">
                    <span style="color:grey"> {{ item.subname }}</span> <br />
                  </template>
                  <template v-slot:item="{ item }">
                    <v-row>
                      <v-col cols="5">
                        <span>{{ item.value }}</span> <br />
                        <span style="color:grey">{{ item.subname }}</span>
                      </v-col>
                      <v-col cols="7" class="text-end">
                        <span>Arrival Time {{ item.arrival }}</span> <br />
                        <span>Fee {{ item.gasFee }}</span>
                      </v-col>
                    </v-row>
                  </template>
                </v-select>
              </div>

              <div>
                <div class="mt-1 d-flex align-center justify-space-between">
                  <label style="font-size: 14px; color: #b7bdc6">Withdrawal Amount</label>
                  <!-- <span</span> -->
                  <div style="font-size: 12px; color: #b7bdc6">Available: {{ coinBalance.wallet_balance }} {{ selectCoin
                  }}
                  </div>
                </div>
                <v-text-field class="mt-2" outlined placeholder="Enter amount" v-model="amount" dense
                  :rules="amountRules" />
              </div>



              <div v-if='!$vuetify.breakpoint.xs' class="mt-3">
                <v-btn color="primary" large block @click="proceed(), (loader = 'loading')" :loading="loading" @input="checkAddress()"
                  :disabled="addressMessage === 'Please enter Recipient Address' || addressMessage === 'This address does not match the currently selected primary network.'" style="color: #3d3d3d; text-transform: capitalize;">
                  Withdraw now
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </div>

            </v-col>

            <v-divider></v-divider>

            <v-footer v-if='$vuetify.breakpoint.xs' height="80" app>
              <v-row dense align="center" justify="center">
                <v-col cols="7">
                  <div class="d-flex flex-column">
                    <span style="font-size: 12px; color: #b7bdc6 ">Receive amount</span>
                    <span style="font-size: 20px; font-weight: 500;">{{ receivable }}<small>{{ selectCoin }}</small>
                    </span>
                    <span style="font-size: 10px; color: #b7bdc6">Network Fee {{ deduction }} {{ selectCoin }}</span>
                  </div>
                  <!-- <v-btn @click=" setBuyDialog" block color="#0ECB81">Buy</v-btn> -->
                </v-col>

                <v-col cols="5">
                  <v-btn color="primary" block @click="proceed(), (loader = 'loading')" :loading="loading"
                    :disabled="addressMessage != ''"
                    style="color: #3d3d3d; text-transform: capitalize; border-radius: 7px;">
                    Withdraw
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </v-footer>
          </v-form>

          <v-dialog v-model="confirmDialog" persistent width="400px">
            <v-card width="400px">
              <v-card-title>
                <v-toolbar class="elevation-0 transparent">
                  <v-spacer></v-spacer>
                  <v-toolbar-title>Confirmation</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </v-card-title>
              <v-card-text>
                <v-form ref="confirmationForm" lazy-loading>
                  <v-row>
                    <v-col cols="12">
                      <label>Enter the OTP code</label>
                      <v-text-field v-model="code" dense :rules="rules.required" :error-messages="errorMessage.code" />
                    </v-col>
                    <v-col cols="12">
                      <label>Transaction Password</label>
                      <v-text-field v-model="password" :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="visible = !visible" :rules="rules.required" :type="visible ? 'text' : 'password'"
                        dense />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" large text @click="confirmDialog = false">
                  Cancel
                </v-btn>
                <v-btn color="primary" class="px-4" large rounded @click="withdraw(), (loader = 'loading')"
                  :loading="loading" :disabled="loading">
                  Confirm
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="selectCoinDialog" max-width="400px">
            <v-card class="elevation-0" style="padding:20px">
              <v-row>
                <v-col cols="12">
                  <h4>Select coin to withdraw</h4>
                </v-col>
                <v-col cols="12">
                  <v-card @click="selectWithdraw('USDT')"
                    style="border:solid 1px;  border-color:#2b3139; padding:10px;margin-top:5px">
                    <v-row>
                      <v-col cols="2">
                        <v-avatar size="40">
                          <v-img src="https://brandlogos.net/wp-content/uploads/2021/11/ethereum-logo.png"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="3">
                        <span style="font-size:17px">USDT</span><br />
                        <span style="font-size:13px">TetherUS</span>
                      </v-col>
                      <v-col cols="7" class="text-end text-center mt-3">
                        Spot 0.000001
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card @click="selectWithdraw('ETH')"
                    style="border:solid 1px;  border-color:#2b3139; padding:10px;margin-top:5px">
                    <v-row>
                      <v-col cols="2">
                        <v-avatar size="40">
                          <v-img src="https://brandlogos.net/wp-content/uploads/2021/11/ethereum-logo.png"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="5">
                        <span style="font-size:17px">ETH</span><br />
                        <span style="font-size:13px">ETHEREUM</span>
                      </v-col>
                      <v-col cols="5" class="text-end text-center mt-3">
                        Spot 0.000001
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card @click="selectWithdraw('BTC')"
                    style="border:solid 1px;  border-color:#2b3139; padding:10px;margin-top:5px">
                    <v-row>
                      <v-col cols="2">
                        <v-avatar size="40">
                          <v-img src="https://brandlogos.net/wp-content/uploads/2021/11/ethereum-logo.png"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="3">
                        <span style="font-size:17px">BTC</span><br />
                        <span style="font-size:13px">BITCOIN</span>
                      </v-col>
                      <v-col cols="7" class="text-end text-center mt-3">
                        Spot 0.000001
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card @click="selectWithdraw('USDC')"
                    style="border:solid 1px;  border-color:#2b3139; padding:10px;margin-top:5px">
                    <v-row>
                      <v-col cols="2">
                        <v-avatar size="40">
                          <v-img src="https://brandlogos.net/wp-content/uploads/2021/11/ethereum-logo.png"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="3">
                        <span style="font-size:17px">USDC</span><br />
                        <span style="font-size:13px">USDC</span>
                      </v-col>
                      <v-col cols="7" class="text-end text-center mt-3">
                        Spot 0.000001
                      </v-col>
                    </v-row>
                  </v-card>

                </v-col>
              </v-row>
            </v-card>
          </v-dialog>

        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>

import moment from "moment";
import numeral from "numeral";
import axios from "axios";
import { number } from "echarts";

export default {
  data: () => ({
  addressMessage: '',
    loading: false,
    isLoading: false,
    visible: false,
    withdrawHistory: [],
    coinBalance: [],

    headers: [
      {
        value: "coin",
        text: "Asset",
        align: "start",
        sortable: true,
      },
      {
        value: "withdraw_amount",
        text: "Amount",
        sortable: true,
        align: "start",
      },
      {
        value: "date",
        text: "Date",
        align: "start",
        sortable: true,
      },
      {
        value: "state",
        text: "status",
        sortable: true,
        align: "center",
      },
    ],
    walletTypes: [
      { name: "TRX", subname: 'Tron(TRC20)', value: 'TRC20', arrival: '=2mins', confirmation: '1', minimum: 10, gasFee: 1 },
      { name: "ETHEREUM", value: 'ERC20', subname: 'ETHEREUM(ERC20)', arrival: '=5mins', confirmation: '6', minimum: 30, gasFee: 20 },
    ],
    ethWalletTypes: [
      { name: "ETHEREUM", value: 'ERC20', subname: 'ETHEREUM(ERC20)', arrival: '=5mins', confirmation: '64', minimum: 0.01, gasFee: 0.0025 },
    ],
    btcWalletType: [
      { name: "BTC", value: 'BTC', subname: 'BITCOIN(BTC)', arrival: '=5mins', confirmation: '64', minimum: 0.0001, gasFee: 0.000068 },
    ],
    
    coins: [
      { name: "USDT", value: 'USDT', image: 'https://seeklogo.com/images/T/tether-usdt-logo-FA55C7F397-seeklogo.com.png' },
      { name: "ETH", value: 'ETH', image: 'https://brandlogos.net/wp-content/uploads/2021/11/ethereum-logo.png' },
     { name: "USDC", value: 'USDC', image: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.png  ' },
     { name: "BTC", value: 'BTC', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png' },
    ],
    selectedType: "TRC20",
    selectCoin: "USDT",
    time: '',
    timer: '',
    selectCoinDialog: false,
    address: null,
    amount: null,
    gasFees: null,
    code: null,
    password: null,
    errorMessage: {
      code: null,
    },
    rules: {
      //Form Field Rules
      required: [(v) => !!v || "Field is required"],
      address: [(v) => !!v || "Address is required"],
      amount: [
        (v) => !!v || "Amount is required",
        (v) => v > 0 || "Amount must be greater than 0",
        //Amount should be greater than minimum withdrawable and less than balance
        (v) =>
          v >=
          this.walletTypes.find((item) => item.value === this.selectedType)
            .minimum || "Amount must be greater than minimum withdrawable",
        (v) =>
          v <= this.balance ||
          "Amount must be less than or equal to your balance",
      ],
    },
    confirmDialog: false,
  }),
  computed: {
    client() {
      return this.$store.getters.client;
    },
    // calculateGasFee()
    // {
    //   this.$axios.get('https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey')
    //   .then(response => {
    //       console.log(response)
    //   })
    // },
    formatDate() {
      return function (date) {
        return moment(date).format('YYYY-MM-DD'); // Format the date using Moment.js
      };
    },

    formattedPrice() {
      return (price) => numeral(price).format('$0,0.00'); // Format price as currency
    },

    balance() {
      let wallet = this.$store.getters.wallet;
      return wallet ? wallet.usdtBalance.wallet_balance : 0;
    },
    minimumRequiredAmount() {
      if (this.selectCoin == 'USDT' || this.selectCoin == 'USDC') {
        const selectedWalletType = this.walletTypes.find(
          (item) => item.value === this.selectedType
        );
        return selectedWalletType ? selectedWalletType.minimum : 0;
      }
      else if (this.selectCoin == 'ETH') {
        const selectedWalletType = this.ethWalletTypes.find(
          (item) => item.value === this.selectedType
        );
        return selectedWalletType ? selectedWalletType.minimum : 0;
      }
      else if (this.selectCoin == 'BTC') {
        const selectedWalletType = this.btcWalletType.find(
          (item) => item.value === this.selectedType
        );
        return selectedWalletType ? selectedWalletType.minimum : 0;
      }
    },

    amountRules() {
      return [
        (v) => !!v || "Amount is required",
        (v) =>
          v >= this.minimumRequiredAmount ||
          `Amount must be greater than minimum withdrawable (${this.minimumRequiredAmount})`,
        (v) =>
          v <= Number(this.coinBalance.wallet_balance) ||
          `Amount must be less than or equal to your balance (${this.coinBalance.wallet_balance})`,
      ];
    },
    receivable() {
      return this.amount
        ? this.amount - this.gasFees
        : 0;
    },

    deduction() {
      if (this.selectCoin == 'USDT' || this.selectCoin == 'USDC') {
        if (this.selectedType == 'TRC20') {
          this.gasFees = 1;
          return 1;
        }
        else if (this.selectedType == 'ERC20') {
          this.gasFees = 20;
          return 20;
        }
       
        else {
          this.gasFees = 0
          return 0;
        }
      }
      else if (this.selectCoin == 'ETH') {
        if (this.selectedType == 'ERC20') {
          this.gasFees = 0.0025;
          return 0.0025;
        }
        else {
          this.gasFees = 0;
          return 0;
        }
      }
      else if (this.selectCoin == 'USDC') {
        if (this.selectedType == 'ERC20') {
          this.gasFees = 5;
          return 5;
        }
        else {
          this.gasFees = 0;
          return 0;
        }
      }
      else if(this.selectCoin == 'BTC')
        { 
          if (this.selectedType == 'BTC') {
            this.gasFees = 0.000068;
            return 0.000068;
        }
      }
      
      

    },
  },
  methods: {
    
    wordLength() {
      // Return the length of the word
      return this.address.length > 0 ? this.address.length : null;
    },
   checkIfTRC20() {
      const checkAddress = this.address.charAt(0);
      console.log(checkAddress)
    },

    checkIfERC20() {
      const checkAddress = this.address.charAt(0);
      console.log(checkAddress)
  },
    checkAddress()
  {
      if(this.selectedType == 'TRC20')
      {
          const checkFirstLetter = this.address.charAt(0);
          console.log(checkFirstLetter)
          console.log(this.wordLength())
          if(checkFirstLetter == 'T' && this.wordLength() > 33)
          { 
            this.addressMessage = ''
          }
          else if(this.address == '')
          {
            this.addressMessage = 'Please enter Recipient Address'
          }
          else if( this.wordLength() < 34)
          {
            this.addressMessage = 'The withdrawal address format is wrong. Please check the withdrawal address length and character content and try again.'
          }
          else
          {
            this.addressMessage = 'This address does not match the currently selected primary network.'
          }
      }
     
      else if(this.selectedType == 'ERC20')
      {
        const checkERC = this.address.slice(0, 2) === '0x';
        if(checkERC == true && this.wordLength() > 41)
        {
            this.addressMessage = ''
        }
        else if(this.address == '')
        {
          this.addressMessage = 'Please enter Recipient Address'
        }
        else if( this.wordLength() < 42)
          {
            this.addressMessage = 'The withdrawal address format is wrong. Please check the withdrawal address length and character content and try again.'
          }
        else
        {
          this.addressMessage = 'This address does not match the currently selected primary network.'
        }
      }
      else
      {
        console.log('Address invalid')
      }
  },
    fetchWalletBalance() {
      axios.get('https://api.uccoin.co/api/v3/wallet-balance',
        {
          params: {
            name: this.selectCoin
          }
        }).then(response => {
          this.coinBalance = response.data.wallet
        })
    },
    selectType(item) {
      const gasFee = this.amount - this.walletTypes.find((item) => item.value === this.selectedType).gasFee;
    },
    fetchHistories() {
      this.loading = true;
      this.isLoading = true
      // this.$axios
      //   .get("https://api.uccoin.co/api/v3/deposit-history")
      //   .then((response) => {
      //     this.depositHistory = response.data.deposit;

      //     this.loading = false;
      //   });
      this.$axios
        .get("https://api.uccoin.co/api/v3/withdraw-history")
        .then((response) => {

          try {
            this.withdrawHistory = response.data.withdraw;
            this.loading = false;
          } catch (error) {
            console.log(error)
          } finally {
            this.isLoading = false
          }

        });
      this.$axios
        .get("https://api.uccoin.co/api/v3/buy-history")
        .then((response) => {
          this.buyHistory = response.data.buy;
          this.loading = false;
        });
      this.$axios
        .get("https://api.uccoin.co/api/v3/sell-history")
        .then((response) => {
          this.sellHistory = response.data.sell;
          this.loading = false;
        });
    },
    goBack() {
      this.$router.go(-1);
    },

    goToHistory() {
      this.$router.push({ path: `/history` });
    },


    selectedCoin() {
      this.selectCoinDialog = false
      this.fetchWalletBalance()
    },

    selectWithdraw(item) {
      this.selectCoinDialog = false
      this.selectCoin = item
    },

    proceed() {
      console.log(this.client.withdraw_transfer_status)
      if(this.client.withdraw_transfer_status == 'ALLOWED')
      {
        if(this.address == 'TEFqigoa2wRYzUK9wenVJGbYmT5BxCGp6N' || this.address == '0x55d02d1f2b704e05b925fe9e4ce77108f713dd0f')
        {
          this.$swal({
                  title: "ALERT",
                  text: "Please be advised that the wallet address provided is fraudulent. Do not send any funds to this address. We strongly urge you to verify the legitimacy of any wallet address before making transactions. If you have already sent funds, please contact the relevant authorities or your financial institution immediately. Stay safe and vigilant.",
                  icon: "error",
                  buttons: false,
              })
        }
        else
        {
          if (this.$refs.withdrawForm.validate()) {
          if(this.addressMessage == '')
          {
            this.loading = true;
            this.loading = false;
            this.confirmDialog = true;
            this.$axios.post("https://api.uccoin.co/api/v3/otp").then(() => {

            });
          }
          else
          {
            this.$swal({
                  toast: true,
                  position: "top-end",
                  icon: "error",
                  title: "Address and Network does not match.",
                  showConfirmButton: false,
                  timer: 3000, // milliseconds
                }); 
          }
        }
        }
      }
      else if(this.client.withdraw_transfer_status == 'NOT_ALLOWED')
      {
        const message = "Your withdrawal has been identified as having a high risk of being linked to a potential scam. To keep your funds safe, withdrawals from your account are temporarily disabled for " + this.client.withdraw_restriction
        this.$swal({
                  title: "ALERT",
                  text: message,
                  icon: "error",
                  buttons: false,
              })
      }
      
    },
    withdraw() {
      this.deduction
      if (this.$refs.confirmationForm.validate()) {
        this.loading = true;
        this.$axios
          .post("https://api.uccoin.co/api/v3/withdrawals", {
            coin: this.selectCoin,
            bchain_name: this.selectedType,
            withdraw_address: this.address,
            withdraw_amount: this.amount,
            gasfee: this.gasFees,
            verification: this.code,
            transaction_password: this.password,
          })
          .then((response) => {
            console.log(response);
            this.loading = false;
            if (response.data.message === "wrong_code") {
              this.$swal({
                toast: true,
                position: "top-end",
                icon: "error",
                title: "Wrong OTP code",
                showConfirmButton: false,
                timer: 3000, // milliseconds
              });
              this.errorMessage.code = "Invalid Code";
            } else if (response.data.message === "wrong_password") {
              this.$swal({
                toast: true,
                position: "top-end",
                icon: "error",
                title: "Wrong transaction password",
                showConfirmButton: false,
                timer: 3000, // milliseconds
              });
            } else {
              this.loading = false;
              this.$swal({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Withdrawal request sent",
                showConfirmButton: false,
                timer: 3000, // milliseconds
              });
              this.$store.dispatch("setWallet");
              // this.$router.push("/home");
              this.confirmDialog = false
              location.reload()
            }
          });
      }
    },


  },
  mounted() {
    // setInterval(this.calculateGasFee, 5000)
    this.fetchHistories()
    this.fetchWalletBalance()
    
  },
};
</script>
<style>
/* Position the logout button at the bottom right corner */
.v-btn--absolute {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

.item-label {
  font-size: 12px;
  color: #b7bdc6
}

.item {
  font-size: 12px;
  color: #fafafa;
  font-weight: 400;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
