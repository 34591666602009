<template>
  <v-row justify="center">

    <div v-if="!$vuetify.breakpoint.xs" style="background-color: #fff;">
      <v-row justify="center">

        <v-col cols="12" sm="12" md="10" lg="8" class="" style=" border-radius: 10px">
          <div class="d-flex align-center py-15">

            <div class="mr-5" style="position: relative;">
              <v-avatar color="#000" class="mx-auto" size="80">
                <!-- <v-img v-if="img" :src="img" cover></v-img>  if image exist -->
                <v-icon size="50" color="#fff">mdi mdi-account</v-icon>
              </v-avatar>

              <div class="d-flex align-center justify-center" style="right: -5px; bottom: -5px; position: absolute;">
                <v-btn color="white" class="ml-1" :ripple="false" fab x-small @click="updateAccountDialog = true">
                  <v-icon color="#000">mdi-square-edit-outline</v-icon>
                </v-btn>
              </div>
            </div>

            <div class="d-flex flex-column align-start">
              <div class="d-flex align-center">

                <span style="font-size: 28px; font-weight: 500; color: #000;">{{ profile.client.email }}</span>
                <v-spacer></v-spacer>

                <!-- <span v-if="profile.client.membership_level == 'NO_BADGE'"
                  style="font-size: 12px; font-weight: 500; color: #000;">NO
                  BADGE</span>
                <span v-else style="font-size: 12px; font-weight: 500">{{ profile.client.membership_level }}</span> -->
                <!-- <v-spacer></v-spacer> -->
              </div>
              <!-- {{ client.user.account_status }} -->
              <div>
                <!-- <v-chip class="ma-2" :color="client.verified ? 'success' : 'danger'" label>
                <v-icon icon="mdi-check-circle-outline" start></v-icon>
                {{ client.verified ? "Verified" : "Unverified" }}
              </v-chip> -->
                <div style="font-size:11px; color: #000;">UID: {{ client.user.user_unique_id }}</div>

              </div>

              <div>
                <v-chip v-if="client.user.account_status == 'Unverified'" class="mt-2">{{ client.user.account_status
                }}</v-chip>
                <v-chip v-else-if="client.user.account_status == 'Verified'" class="mt-2"> <v-icon color="success"
                    size="15">mdi-check-circle-outline</v-icon>{{ client.user.account_status }}</v-chip>
                <v-chip class="mt-2 ml-2" v-if="profile.client.membership_level == 'NO_BADGE'"
                  style="font-size: 12px; font-weight: 500">{{ $t('profile.noBadge') }}</v-chip>
                <v-chip class="mt-2 ml-2" v-else style="font-size: 12px; font-weight: 500"> <v-icon
                    color="yellow">mdi-crown-circle</v-icon> {{ profile.client.membership_level }}</v-chip>
              </div>
            </div>
          </div>
        </v-col>

        <!-- <v-col cols="12" xs="12" lg="10" xl="10">
          <div style="padding: 150px;" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.md ? 'pa-5' : 'pa-15'">
            <v-row justify="center">
              <v-col cols="12" xs="12" sm="10" md="6" lg="6">
                <div>
                  <h2
                    :class="$vuetify.breakpoint.xs ? 'display-1 text-center mt-10' : $vuetify.breakpoint.sm ? 'display-2' : ''"
                    style="color: #1f2933; font-size: 48px; font-weight: 700;">Refer Friends.
                  </h2>
                  <h2
                    :class="$vuetify.breakpoint.xs ? 'display-1 text-center' : $vuetify.breakpoint.sm ? 'display-2' : ''"
                    style="color: #1f2933; font-size: 32px; font-weight: 500;">Get 100 USDT Trading Fee Credit Each</h2>
                </div>
                <div class="mt-10">
                  <v-text-field class="mt-2" light outlined dense :value="referralCode" @click:append="copyCode()"
                    :append-icon="'mdi-content-copy'">
                  </v-text-field>
                </div>
              </v-col>

            </v-row>
          </div>
        </v-col> -->
      </v-row>
    </div>

    <div v-else style="background-color: #fff;">
      <v-row>
        <v-col cols="12" class="mt-5">
          <div class="d-flex flex-column align-center py-10">

            <div class="" style="position: relative;">
              <v-avatar color="#000" class="mx-auto" size="80">
                <!-- <v-img v-if="img" :src="img" cover></v-img>  if image exist -->
                <v-icon size="50" color="#fff">mdi mdi-account</v-icon>
              </v-avatar>

              <div class="d-flex align-center justify-center" style="right: -5px; bottom: -5px; position: absolute;">
                <v-btn color="white" class="ml-1" :ripple="false" fab x-small @click="updateAccountDialog = true">
                  <v-icon color="#000">mdi-square-edit-outline</v-icon>
                </v-btn>
              </div>
            </div>

            <div class="d-flex align-center mt-5">
              <span style="font-size: 18px; font-weight: 500; color: #000">{{ profile.client.email }}</span>
            </div>

            <div style="font-size:12px; color: #000;">UID: {{ client.user.user_unique_id }}</div>

            <div>
              <v-chip v-if="client.user.account_status == 'Unverified'" class=" mt-2">{{ client.user.account_status
              }}</v-chip>
              <v-chip v-else-if="client.user.account_status == 'Verified'" class="mt-2"> <v-icon color="success"
                  size="15">mdi-check-circle-outline</v-icon>{{ client.user.account_status }}</v-chip>
              <v-chip class="mt-2 ml-2" v-if="profile.client.membership_level == 'NO_BADGE'"
                style="font-size: 12px; font-weight: 500">{{ $t('profile.noBadge') }}</v-chip>
              <v-chip class="mt-2 ml-2" v-else style="font-size: 12px; font-weight: 500"> <v-icon
                  color="yellow">mdi-crown-circle</v-icon> {{ profile.client.membership_level }}</v-chip>
            </div>

          </div>
        </v-col>
      </v-row>
    </div>

    <v-col cols="12" xs="12" md="10" lg="8">

      <span style="font-weight: 500;font-size: 20px; line-height: 28px;">{{ $t('profile.accountLimits') }}</span>

      <v-row justify="space-between">
        <v-col cols="12">

          <div class="mt-5 d-flex align-center justify-space-between">
            <span style="color:#848E9D; font-size: 14px;">{{ $t('profile.fiat') }}</span>
            <span style="font-size: 14px;">
              50K USD Daily
            </span>
          </div>

          <div class="mt-5 d-flex align-center justify-space-between">
            <span style="color:#848E9D; font-size: 14px;">{{ $t('profile.cryptoDeposit') }}</span>
            <span style="font-size: 14px;">
              Unlimited
            </span>
          </div>

          <div class="mt-5 d-flex align-center justify-space-between">
            <span style="color:#848E9D; font-size: 14px;">{{ $t('profile.cryptoWithdrawal') }}</span>
            <span style="font-size: 14px;">
              8M USDT Daily
            </span>
          </div>

          <div class="mt-5 d-flex align-center justify-space-between">
            <span style="color:#848E9D; font-size: 14px;">{{ $t('profile.p2p') }}</span>
            <span style="font-size: 14px;">
              Unlimited
            </span>
          </div>
        </v-col>

      </v-row>

      <v-divider color="#b7bdc6 "></v-divider>

      <span style="font-weight: 500;font-size: 20px; line-height: 28px;">{{ $t('profile.personalInformation') }}</span>

      <v-row justify="space-between">
        <v-col cols="12">

          <div class="mt-5 d-flex align-center justify-space-between">
            <span style="color:#848E9D; font-size: 14px;">{{ $t('profile.name') }}</span>
            <span v-if="profile.name" style="color:#B7BDC6; font-weight: 500; font-size: 14px;">
              {{ profile.name }}
            </span>
            <span v-else style="font-size: 14px;">
              Not Set
            </span>
          </div>

          <div class="mt-5 d-flex align-center justify-space-between">
            <span style="color:#848E9D; font-size: 14px;">{{ $t('profile.gender') }}</span>
            <span v-if="profile.gender" style="color:#B7BDC6; font-weight: 500; font-size: 14px;">
              {{ profile.gender }}
            </span>
            <span v-else style="font-size: 14px;">
              {{ $t('profile.notSet') }}
            </span>
          </div>

          <div class="mt-5 d-flex align-center justify-space-between">
            <span style="color:#848E9D; font-size: 14px;">{{ $t('profile.dateOfBirth') }}</span>
            <span v-if="profile.date_of_birth" style="color:#B7BDC6; font-weight: 500; font-size: 14px;">
              {{ profile.date_of_birth }}
            </span>
            <span v-else style="font-size: 14px;">
              {{ $t('profile.notSet') }}
            </span>
          </div>

          <div class="mt-5 d-flex align-center justify-space-between">
            <span style="color:#848E9D; font-size: 14px;">{{ $t('profile.address') }}</span>
            <span v-if="profile.address" style="color:#B7BDC6; font-weight: 500; font-size: 14px;">
              {{ profile.address }}
            </span>
            <span v-else style="font-size: 14px;">
              {{ $t('profile.notSet') }}
            </span>
          </div>

          <div class="mt-5 d-flex align-center justify-space-between">
            <span style="color:#848E9D; font-size: 14px;">{{ $t('profile.mobile') }}</span>
            <span v-if="client.user.mobile_number" style="color:#B7BDC6; font-weight: 500; font-size: 14px;">
              {{ client.user.mobile_number }}
            </span>
            <span v-else style="font-size: 14px;">
              {{ $t('profile.notSet') }}
            </span>
          </div>
        </v-col>

        <div>
          <v-alert text type="warning">
            {{ $t('profile.alertMessage') }}
            <a href="https://direct.lc.chat/18298131/">
              {{ $t('profile.customerSupport') }}
            </a>
          </v-alert>
        </div>

      </v-row>

      <v-divider color="#b7bdc6 "></v-divider>

      <v-dialog v-model="updateAccountDialog" persistent width="400px">
        <v-card class="pa-5" color="#272727">
          <v-card-title> {{ $t('profile.editAccount') }} </v-card-title>

          <v-card-text>
            <v-form ref="confirmationForm" lazy-loading>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Legal Name" v-model="editedDetails.legal_name" />
                </v-col>

                <v-col cols="6">
                  <v-select v-model="editedDetails.gender" :items="genderOptions" label="Gender">
                  </v-select>
                </v-col>

                <v-col cols="6">
                  <v-text-field label="Date of Birth" v-model="editedDetails.date_of_birth" />
                </v-col>

                <v-col cols="12">
                  <v-text-field label="Address" v-model="editedDetails.address" />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-row>
              <v-col cols="6">
                <v-btn @click="updateAccountDialog = false" color="#9493AC" class="custom-btn" block large>
                  {{ $t('dialog.cancel') }}
                </v-btn>
              </v-col>

              <v-col cols="6">
                <v-btn color="primary" class="custom-btn" block large @click="updateMyProfile(), (loader = 'loading')"
                  :loading="loading" :disabled="loading">
                  {{ $t('dialog.confirm') }}
                  <template v-slot:loader>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmPasswordDialog" persistent width="400px">
        <v-card width="400px">
          <v-card-title>
            <v-toolbar class="elevation-0 transparent">
              <v-spacer></v-spacer>
              <v-toolbar-title>{{ $t('dialog.confirmation') }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-form ref="confirmationForm" lazy-loading>
              <v-row>
                <v-col cols="12">
                  <label>{{ $t('dialog.verificationCode') }}</label>
                  <v-text-field v-model="verification_code" background-color="#29313e" dense />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" large text @click="confirmPasswordDialog = false">
              {{ $t('dialog.cancel') }}
            </v-btn>
            <v-btn color="primary" class="px-4" large rounded @click="savePassword(), (loader = 'loading')"
              :loading="loading" :disabled="loading">
              {{ $t('dialog.confirm') }}
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmTransactionPasswordDialog" persistent width="400px">
        <v-card width="400px">
          <v-card-title>
            <v-toolbar class="elevation-0 transparent">
              <v-spacer></v-spacer>
              <v-toolbar-title>{{ $t('dialog.confirmation') }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-form ref="confirmationForm" lazy-loading>
              <v-row>
                <v-col cols="12">
                  <label>{{ $t('dialog.verificationCode') }}</label>
                  <v-text-field v-model="verification_code" background-color="#29313e" dense />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" large text @click="confirmTransactionPasswordDialog = false">
              {{ $t('dialog.cancel') }}
            </v-btn>
            <v-btn color="primary" class="px-4" large rounded @click="saveTransactionPassword(), (loader = 'loading')"
              :loading="loading" :disabled="loading">
              {{ $t('dialog.confirm') }}
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import VueQrcode from "@chenfengyuan/vue-qrcode";

import { fetchProfile } from "@/services/profileServices";

export default {
  components: {
    VueQrcode,
  },

  data: () => ({
    confirmPasswordDialog: false,
    confirmTransactionPasswordDialog: false,
    oneStepDialog: false,
    whitelistlDialog: false,
    updateAccountDialog: false,
    verification_code: "",
    loading: false,
    visible: false,
    overviewTotal: [],
    profile: [],
    e1: 1,
    topAsset: [],
    referralCode: "",

    genderOptions: ["Male", "Female"],

    pnl: "",
    pnlPercentage: "",
    futurePnl: "",
    futurePnlPercentage: "",
    totalPnl: "",
    totalPnlPercentage: "",

    editedDetails: {
      legal_name: "",
      gender: "",
      date_of_birth: "",
      address: "",
      mobile: "",
    },

    defaultDetails: {
      legal_name: "",
      gender: "",
      date_of_birth: "",
      address: "",
      mobile: "",
    },

    oldLoginPassword: "",
    loginPassword: "",
    loginPassword_confirmation: "",

    oldTransactionPassword: "",
    transactionPassword: "",
    transactionPassword_confirmation: "",
    items: [
      {
        title: "Verification",
        to: "change-password",
        value: "Verified",
      },
      {
        title: "Regular",
        to: "change-transaction-password",
        value: "Regular",
      },
    ],
  }),
  computed: {
    client() {
      return this.$store.getters.client;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  mounted() {
    this.fetchProfileData()
    this.fetchOverview();
    this.fetchProfile();
    this.topAssets();
    this.getPnl();
    this.fetchReferralCode();
  },

  methods: {
    copyCode() {
      navigator.clipboard.writeText(this.referralCode);
      Notify.success("Copied to clipboard");
      //   this.$swal({
      //     toast: true,
      //     position: "top-end",
      //     icon: "success",
      //     title: "Copied to clipboard",
      //     showConfirmButton: false,
      //     timer: 3000, // milliseconds
      //   });
    },
    fetchReferralCode() {
      this.loading = false;
      this.$axios
        .get("https://api.uccoin.co/api/v3/get-referral")
        .then((response) => {
          this.referralCode = `m.umichain.io/signup/${response.data.referral}`;
        });
    },
    getPnl() {
      // console.log('wowowowow')
      axios.get("https://api.uccoin.co/api/v3/pnl").then((response) => {
        this.getPnl = response.data.pnl;
        (this.pnlPercentage = response.data.percentage),
          (this.futurePnl = response.data.futurePnl);
        (this.futurePnlPercentage = response.data.futurePnlPercentage),
          (this.totalPnl = response.data.totalPnl),
          (this.totalPnlPercentage = response.data.totalPercentage);
      });
    },

    fetchProfile() {
      axios.get("https://api.uccoin.co/api/v3/profile").then((response) => {
        this.profile = response.data.profile;
      });
    },

    async fetchProfileData() {
      try {
        let profile = JSON.parse(localStorage.getItem('profile'))
        if (!profile) {
          profile = await fetchProfile();
          localStorage.setItem('profile', JSON.stringify(profile));
        }
        this.profile = profile;
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    },

    topAssets() {
      axios
        .get("https://api.uccoin.co/api/v3/top-assets")
        .then((response) => {
          this.topAsset = response.data.assets;
        });
    },

    updateDetails() {
      this.editedDetails.legal_name = this.profile.name;
      this.editedDetails.gender = this.profile.gender;
      this.editedDetails.date_of_birth = this.profile.date_of_birth;
      this.editedDetails.address = this.profile.address;

      this.updateAccountDialog = true;
    },

    updateMyProfile() {
      axios.get("https://api.uccoin.co/api/v3/my-profile", {
        params: {
          client_id: this.profile.id,
          name: this.editedDetails.legal_name,
          gender: this.editedDetails.gender,
          date_of_birth: this.editedDetails.date_of_birth,
          address: this.editedDetails.address,
        },
      });

      this.updateAccountDialog = false;
    },

    addMyProfile() {
      axios.post("https://api.uccoin.co/api/v3/update-profile", {
        params: {
          client_id: this.client.id,
          name: this.editedDetails.legal_name,
          gender: this.editedDetails.gender,
          date_of_birth: this.editedDetails.date_of_birth,
          address: this.editedDetails.address,
        },
      });
    },

    logout() {
      this.loading = false;
      this.$store.dispatch("logout");
      setTimeout(() => {
        this.loading = false;
        this.$router.push("/signin");
      }, 1000);
    },

    copyUserId() {
      const el = document.createElement("textarea");
      el.value = this.user.user_unique_id;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Copied to clipboard",
        showConfirmButton: false,
        timer: 3000, // milliseconds
      });
    },

    fetchOverview() {
      axios.get("https://api.uccoin.co/api/v3/overview").then((response) => {
        this.overviewTotal = response.data;
      });
    },

    confirmPassword() {
      axios
        .get("https://api.uccoin.co/api/v2/get-code-for-password")
        .then((response) => {
          this.confirmPasswordDialog = true;
        });
    },

    confirmTransactionPassword() {
      axios
        .get("https://api.uccoin.co/api/v2/get-code-for-password")
        .then((response) => {
          this.confirmTransactionPasswordDialog = true;
        });
    },
    savePassword() {
      this.loading = false;
      if (this.loginPassword != this.loginPassword_confirmation) {
        swal.fire({
          position: "center",
          toast: true,
          type: "error",
          icon: "error",
          text: "Password does not match",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loading = false;
      } else {
        axios
          .get("https://api.uccoin.co/api/v2/update-password", {
            params: {
              oldLoginPassword: this.oldLoginPassword,
              password: this.loginPassword,
              verification_code: this.verification_code,
            },
          })
          .then((response) => {
            if (response.data.message == "dont_match") {
              swal.fire({
                position: "center",
                toast: true,
                type: "error",
                icon: "error",
                text: "Old password dont match",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
            } else if (response.data.message == "same_previous_password") {
              swal.fire({
                position: "center",
                toast: true,
                type: "error",
                icon: "error",
                text: "Recently used password. Please make another.",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
            } else {
              swal.fire({
                position: "center",
                toast: true,
                type: "success",
                icon: "success",
                text: "Passwords Successfully Set up",
                showConfirmButton: false,
                timer: 1500,
              });

              this.oldLoginPassword = "";
              this.loginPassword = "";
              this.loginPassword_confirmation = "";
              this.loading = false;
            }
          });
      }
    },

    saveTransactionPassword() {
      this.loading = false;
      if (this.transaction_password != this.transactionPassword_confirmation) {
        swal.fire({
          position: "center",
          toast: true,
          type: "error",
          icon: "error",
          text: "Password does not match",
          showConfirmButton: false,
          timer: 1500,
        });

        this.loading = false;
      } else {
        axios
          .get("https://api.uccoin.co/api/v3/update-transaction-password", {
            params: {
              id: sessionStorage.getItem("user-id"),
              oldTransactionPassword: this.oldTransactionPassword,
              transactionPassword: this.transactionPassword,
            },
          })
          .then((response) => {
            if (response.data.message == "dont_match") {
              swal.fire({
                position: "center",
                toast: true,
                type: "error",
                icon: "error",
                text: "Old password dont match",
                showConfirmButton: false,
                timer: 1500,
              });
              this.loading = false;
            } else {
              swal.fire({
                position: "center",
                toast: true,
                type: "success",
                icon: "success",
                text: "Passwords Successfully Set up",
                showConfirmButton: false,
                timer: 1500,
              });

              this.oldTransactionPassword = "";
              this.transactionPassword = "";
              this.transactionPassword_confirmation = "";
              this.loading = false;
            }
          });
      }
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
/* Position the logout button at the bottom right corner */
.v-btn--absolute {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

.custom-button-width {
  width: 100px;
}

/* Customize the label color for the specific input */
.custom-invitation-link .v-label {
  color: black !important;
}

/* Customize the input (value) color for the specific input */
.custom-invitation-link .v-input__control input {
  color: black !important;
}

.custom-elevation {
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.5);
  /* Adjust the shadow color and opacity */
}

/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>


<!-- <v-divider></v-divider>

<v-col cols="12">
              <h5 class="mb-5">Top Asset</h5>
              <v-row v-for="(item, index) in topAsset" :key="index" justify="space-between" align="center">
                <v-col cols="4" align="start">
                  <div class="d-flex flex-column">
                    <div class="mb-1">
                      <v-avatar class="mx-auto" size="40">
                        <v-img :src="item.coin.image" cover></v-img>
                      </v-avatar>
                    </div>

                    <span>{{ item.coin.name }}</span>
                  </div>
                </v-col>

<v-col cols="4">
                  ${{ parseFloat(item.wallet_balance).toFixed(3) }}
                  <br />
                </v-col>

<v-col cols="4" align="end"> Sparkline </v-col>
              </v-row>
            </v-col>

<v-divider></v-divider>

<v-col cols="12">
              <h5 class="mb-5">Wallet Overview</h5>

              <v-row justify="space-between" align="center">
                <v-col cols="4"> Spot </v-col>

<v-col cols="4">
                  {{ parseFloat(overviewTotal.spotTotal).toFixed(2) }}
                </v-col>

<v-col cols="4">
                  <v-icon v-if="pnlPercentage > 0" style="color: green">mdi-arrow-top-right-thin</v-icon>
                  <v-icon v-else-if="pnlPercentage < 0" style="color: red">mdi-arrow-bottom-right-thin</v-icon>
                  {{ parseFloat(pnlPercentage).toFixed(2) }}%
                </v-col>

<v-col cols="4"> Futures </v-col>

<v-col cols="4">
                  {{ parseFloat(overviewTotal.futureTotal).toFixed(2) }}
                </v-col>

<v-col cols="4">
                  <v-icon v-if="futurePnlPercentage > 0" style="color: green">mdi-arrow-top-right-thin</v-icon>
                  <v-icon v-else-if="futurePnlPercentage < 0" style="color: red">mdi-arrow-bottom-right-thin</v-icon>
                  {{ parseFloat(futurePnlPercentage).toFixed(2) }}%
                </v-col>

<v-col cols="4"> Earn </v-col>

<v-col cols="4">
                  {{ parseFloat(overviewTotal.earnTotal).toFixed(2) }}
                </v-col>

<v-col cols="4">
                  <v-icon style="color: red">mdi-arrow-bottom-right-thin</v-icon>
                  0.00%
                </v-col>
              </v-row>
            </v-col> -->
