<template>
  <v-row justify="center">

    <!-- <v-col lg="12">
      <div class="d-flex align-center">
        <v-btn icon color="#9493AC" to="/profile" class="mr-2">
          <v-icon small>fa-solid fa-chevron-left</v-icon>
        </v-btn>
        Profile
      </div>
    </v-col> -->

    <div style="background-color: #fff;">
      <v-row justify="center">
        <v-col cols="12" xs="12" lg="10" xl="10">
          <div style="padding: 150px;" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.md ? 'pa-5' : 'pa-15'">
            <v-row justify="center">
              <v-col cols="12" xs="12" sm="10" md="6" lg="6">
                <div>
                  <h2
                    :class="$vuetify.breakpoint.xs ? 'display-1 text-center mt-10' : $vuetify.breakpoint.sm ? 'display-2' : ''"
                    style="color: #1f2933; font-size: 48px; font-weight: 700;">{{ $t('referral.refer') }}.
                  </h2>
                  <h2
                    :class="$vuetify.breakpoint.xs ? 'display-1 text-center' : $vuetify.breakpoint.sm ? 'display-2' : ''"
                    style="color: #1f2933; font-size: 32px; font-weight: 500;">{{ $t('referral.subheader') }}</h2>
                </div>
                <div class="mt-10">
                  <v-text-field class="mt-2" light outlined dense :value="referralCode" @click:append="copyCode()"
                    :append-icon="'mdi-content-copy'">
                  </v-text-field>
                </div>
              </v-col>

              <v-col cols="12" xs="12" sm="8" md="6" lg="6" align="center">
                <div>
                  <v-img max-height="200px" max-width="300px" style="border-radius:10px"
                    src="https://img.freepik.com/premium-vector/referral-program-flat-color-referral-program-banner-vector-illustration_748571-1804.jpg"></v-img>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="my-15">
      <v-row justify="center">
        <v-col cols="12" xs="12" lg="10" xl="10">
          <div :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.md ? 'pa-5' : 'pa-15'">

            <v-row justify="center">

              <v-col cols="12" align="center">

                <div style="font-size: 28px; font-weight: 700; margin-bottom: 20px;  color: #F7F9FC; ">{{
                  $t('referral.how') }}</div>
              </v-col>

              <v-col cols="12" xs="12" md="4" lg="4" align="center" class="my-5">
                <div class="d-flex flex-column align-center">
                  <v-icon size="50">
                    mdi mdi-account-plus-outline
                  </v-icon>

                  <span class="title">
                    {{ $t('referral.step1Header') }}
                  </span>

                  <span class="desc">
                    {{ $t('referral.step1') }}</span>
                </div>
              </v-col>

              <v-col cols="12" xs="12" md="4" lg="4" align="center" class="my-5">
                <div class="d-flex flex-column align-center">

                  <v-icon size="50">
                    mdi mdi-cellphone-check
                  </v-icon>

                  <span class="title">
                    {{ $t('referral.step2Header') }}
                  </span>

                  <span class="desc">
                    {{ $t('referral.step2') }}
                  </span>
                </div>

              </v-col>

              <v-col cols="12" xs="12" md="4" lg="4" align="center" class="my-5">

                <div class="d-flex flex-column align-center">

                  <v-icon size="50">
                    mdi mdi-gift-open-outline
                  </v-icon>

                  <span class="title">
                    {{ $t('referral.step3Header') }}
                  </span>

                  <span class="desc">
                    {{ $t('referral.step3') }}
                  </span>
                </div>

              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="my-15">
      <v-row justify="center">
        <v-col cols="12" xs="12" lg="10">
          <div>

            <div style="font-size: 28px; font-weight: 700;" class="mb-10">
              {{ $t('referral.how2') }}
            </div>

            <div>

              <p style="line-height: 2rem;">
                {{ $t('referral.invite') }}
              <p>

                {{ $t('referral.inviteStep1') }}
              </p>

              <p>
                {{ $t('referral.inviteStep2') }}
              </p>

              <p>
                {{ $t('referral.inviteStep3') }}
              </p>

              </p>


              <p style="line-height: 2rem;">
                {{ $t('referral.invite2') }}
              </p>

            </div>
          </div>
        </v-col>
      </v-row>
    </div>


    <!-- <v-container>
      <v-card class=" mt-12" rounded>
              <v-row justify="space-between" style="padding:10px;border-radius:20px" bottom>
                <v-col cols="4" style="font-size:15px;" class="text-start">Referral Link</v-col>

                <v-col cols="8" style="font-size:15px;" class="text-end">{{ referralCode }}<v-icon class="ml-2"
                    style="font-size:15px" @click="copyCode()">mdi-content-copy</v-icon></v-col>
              </v-row>
              </v-card>
              </v-container> -->
  </v-row>
</template>
<script>

import { Notify } from "notiflix/build/notiflix-notify-aio";
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
  components: {
    VueQrcode,
  },
  data: () => ({
    loading: false,
    referralCode: "",
  }),
  methods: {
    copyCode() {
      navigator.clipboard.writeText(this.referralCode);
      Notify.success("Copied to clipboard");
      //   this.$swal({
      //     toast: true,
      //     position: "top-end",
      //     icon: "success",
      //     title: "Copied to clipboard",
      //     showConfirmButton: false,
      //     timer: 3000, // milliseconds
      //   });
    },
    fetchReferralCode() {
      this.loading = true;
      this.$axios
        .get("https://api.uccoin.co/api/v3/get-referral")
        .then((response) => {
          this.referralCode = `uccoin.io/signup/${response.data.referral}`;
        });
    },
  },
  mounted() {
    this.fetchReferralCode();
  },
};
</script>
<style>
.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #F7F9FC;
}

.desc {
  color: #F7F9FC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  width: 75%;
}

.list-item {
  font-size: 14px;
  /* line-height: 22px; */
  font-weight: 400;
  margin-bottom: 16px
}

/* Position the logout button at the bottom right corner */
.v-btn--absolute {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
