import axios from 'axios';

const apiUrl = 'https://api.uccoin.co/api'

export const getDepositHistory = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/deposit-history`)
        return response.data.deposit
    } catch (error) {
        console.error('Error fetching deposit history:', error);
        throw error;
    }
};

export const getWithdrawHistory = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/withdraw-history`)
        return response.data.withdraw
    } catch (error) {
        console.error('Error fetching withdraw history:', error);
        throw error;
    }
};

export const getBuyHistory = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/buy-history`)
        return response.data.buy
    } catch (error) {
        console.error('Error fetching buy history:', error);
        throw error;
    }
};

export const getSellHistory = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/sell-history`)
        return response.data.sell
    } catch (error) {
        console.error('Error fetching sell history:', error);
        throw error;
    }
};
