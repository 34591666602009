import axios from "axios";

const apiUrl = 'https://api.uccoin.co/api'

export const fetchAllPairs = async (clientId) => {
    try {
        const response = await axios.get(`${apiUrl}/v3/all-pairs`, {
            params: { client_id: clientId }
        });
        if (response && response.data && response.data.coinpair) {
            return response.data.coinpair;
        } else {
            throw new Error('Response data or coinpair is undefined.');
        }
    } catch (error) {
        console.error('Error fetching all pairs:', error);
        throw error;
    }
};

export const fetchCoinsFavorites = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/favorites`);
        return response.data.coinpair;
    } catch (error) {
        console.error('Error fetching favorite coins:', error);
        throw error;
    }
};

export const fetchPowPairs = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/inno-coins`, {
            params: { pair_type: 'ETH' }
        });

        if (response && response.data && response.data.coinpair) {
            return response.data.coinpair;
        } else {
            throw new Error('Response data or coinpair is undefined.');
        }
    } catch (error) {
        console.error('Error fetching Pow pairs:', error);
        throw error;
    }
};

export const fetchPosPairs = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/inn-coins`, {
            params: { pair_type: 'ALT' }
        });

        if (response && response.data && response.data.coinpair) {
            return response.data.coinpair;
        } else {
            throw new Error('Response data or coinpair is undefined.');
        }
    } catch (error) {
        console.error('Error fetching Pos pairs:', error);
        throw error;
    }
};

export const fetchInnoPairs = async () => {
    try {
        const response = await axios.get(`${apiUrl}/v3/main-coins`, {
            params: { pair_type: 'MAIN' }
        });

        if (response && response.data && response.data.coinpair) {
            return response.data.coinpair;
        } else {
            throw new Error('Response data or coinpair is undefined.');
        }
    } catch (error) {
        console.error('Error fetching Pos pairs:', error);
        throw error;
    }
};




