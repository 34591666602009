<template>
    <v-container>
        <v-row>

            <v-col v-if="!$vuetify.breakpoint.xs" cols="12">
                <v-toolbar class="transparent elevation-0">
                    <v-btn icon @click="goBack">
                        <icon icon="material-symbols:arrow-back" width="24" height="24"></icon>
                    </v-btn>
                    <v-toolbar-title>Transactions</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-btn v-if="$vuetify.breakpoint.xs" @click="openCalendarDialog" class="mr-1" :ripple="false" icon>
                        <v-icon>
                            mdi mdi-calendar-blank-outline
                        </v-icon>
                    </v-btn>
                </v-toolbar>
            </v-col>

            <v-col v-if="!$vuetify.breakpoint.xs" cols="12" sm="6" md="3">
                <v-menu ref="menuDate" v-model="menuDate" :close-on-content-click="false" :return-value.sync="dates"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dates" label="Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker range v-model="dates" no-title scrollable>

                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menuDate = false">
                            Cancel
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.menuDate.save(dates)">
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <!-- 
            <v-col cols="12" sm="6" md="3">
                {{ dates }}
            </v-col> -->

            <v-col v-if="!$vuetify.breakpoint.xs" cols="12" xs="12">
                <v-card class="pa-5" color="#00000000" style="border-radius: 5px; border: 1px solid #b7bdc6 ;">
                    <v-data-table :loading="isLoading" :headers="headers" :items="transactions" :items-per-page="20"
                        @click:row="rowClicked($event)" class="transparent">

                        <template v-slot:item.balance="{ item }">
                            <span class="">{{ item.transaction.transaction_type }}</span><br />
                        </template>

                        <template v-slot:item.accumulation="{ item }">
                            <span class="">{{ parseFloat(item.balance).toFixed(2) - parseFloat(item.base_balance).toFixed(2)
                            }}</span><br />
                            <!-- <span style="font-size:10px;color:grey">${{ parseFloat(item.usdt_value).toFixed(2) }}</span> -->
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>

            <v-col v-else cols="12">
                <!-- mobile view -->
                <div>
                    <v-row class="mt-4">
                        <v-col cols="6" class="text-center">
                            <p class="mb-0">Earn to Spot(BTC)</p>
                            <span class="greenPrimary--text">+0</span>
                        </v-col>
                        <v-col cols="6" class="text-center">
                            <p class="mb-0">Earn to Spot(BTC)</p>
                            <span class="redPrimary--text">-0</span>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>

                    <div class="d-flex align-center">
                        <div>
                            <v-btn @click="openAllHoldingsDialog" text>
                                <span class="white--text">All Holdings</span>
                                <icon icon="material-symbols:arrow-drop-down" width="24" height="24"></icon>
                            </v-btn>
                        </div>

                        <div>
                            <v-btn text>
                                <span class="white--text">All Types</span>
                                <icon icon="material-symbols:arrow-drop-down" width="24" height="24"></icon>
                            </v-btn>
                        </div>
                    </div>

                    <div v-show="!loading">
                        <div v-if="transactions.length >= 0">
                            <v-row class="mt-4" v-for="(transaction, index ) in transactions " :key="index"
                                style="border-bottom: 1px solid #b7bdc6;">

                                <v-col cols="6" class="text-start">
                                    <h6 class="white--text">
                                        {{ transaction.transaction_type }}
                                    </h6>
                                </v-col>

                                <v-col cols="6" class="text-end">
                                    <h6 :class="amountColor(
                                        transaction.transaction_type
                                    )
                                        ">
                                        {{
                                            transaction.transaction_type ==
                                            "WITHDRAW"
                                            ? `- ${transaction.amount} `
                                            : transaction.amount
                                        }}
                                    </h6>
                                </v-col>
                            </v-row>
                        </div>

                        <v-row v-else class="text-center mt-12">
                            <v-col cols="12">
                                <div class="d-flex align-center justify-center mt-10" style="height: 100%">
                                    <div class="d-flex flex-column align-center justify-center">
                                        <v-icon x-large color="#b7bdc6">
                                            mdi mdi-note-search-outline
                                        </v-icon>
                                        <span style="font-size: 12px; color: #b7bdc6">No Records Found</span>

                                        <div class="mt-3">
                                            <ButtonComponent style="color: #1C1D28 ;" color="primary"
                                                label="Subscribe Now" />
                                        </div>

                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-show="loading">
                        <v-row justify="center" class="text-center mt-12">
                            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                        </v-row>
                    </div>
                </div>

                <DialogComponent dialogTitle="Select Product" :bindData="isAllHoldingDialogOpen"
                    @update:bindData="updateDialogState">
                    <template v-slot:default>

                        <div>
                            <v-chip filter outlined>
                                All Holdings
                            </v-chip>
                        </div>

                        <div class="mt-5">
                            <span>Simple Earn Products</span>

                            <div class="m-2">
                                <v-chip-group v-model="amenities" column multiple>
                                    <v-chip filter outlined>
                                        All
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Flexible
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Locked
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Eth Staking
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Flexible Trial Fund
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Fixed Savings
                                    </v-chip>
                                </v-chip-group>

                            </div>
                        </div>

                        <div class="mt-5">
                            <span>High Yield Products</span>

                            <div class="m-2">
                                <v-chip-group v-model="amenities" column multiple>
                                    <v-chip filter outlined>
                                        All
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Dual Investment
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Liquidity Farming
                                    </v-chip>
                                    <v-chip filter outlined>
                                        DeFi Staking
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Range Bound
                                    </v-chip>
                                </v-chip-group>
                            </div>
                        </div>

                        <v-row class="mt-10" dense>
                            <v-col cols="6">
                                <ButtonComponent color="#b7bdc6 " :block="true" label="Reset" />
                            </v-col>
                            <v-col cols="6">
                                <ButtonComponent style="color: #1C1D28;" color="primary" :block="true" label="Confirm" />
                            </v-col>
                        </v-row>

                    </template>
                </DialogComponent>

                <DialogComponent dialogTitle="Transactions" :bindData="isCalendarDialogOpen"
                    @update:bindData="updateDialogState">
                    <template v-slot:default>
                        <v-row>
                            <v-col cols="12">
                                <v-date-picker full-width v-model="dates" range></v-date-picker>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="dateRangeText" label="Date range" prepend-icon="mdi-calendar"
                                    readonly></v-text-field>
                                <!-- model: {{ dates }} -->
                            </v-col>
                        </v-row>
                    </template>
                </DialogComponent>

                <DialogComponent dialogTitle="Select Types">
                    <template v-slot:default>

                        <div class="mt-5">
                            <span>Direction</span>

                            <div class="m-2">
                                <v-chip-group v-model="amenities" column multiple>
                                    <v-chip filter outlined>
                                        All
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Earn to Spot
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Spot to Earn
                                    </v-chip>
                                </v-chip-group>

                            </div>
                        </div>

                        <div class="mt-5">
                            <span>Transaction Types</span>

                            <div class="m-2">
                                <v-chip-group v-model="amenities" column multiple>
                                    <v-chip filter outlined>
                                        All
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Subscription
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Redemption
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Income
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Trade
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Internal Transfer
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Settlement
                                    </v-chip>
                                    <v-chip filter outlined>
                                        Refund
                                    </v-chip>
                                </v-chip-group>
                            </div>
                        </div>

                        <v-row class="mt-10" dense>
                            <v-col cols="6">
                                <ButtonComponent color="#b7bdc6 " :block="true" label="Reset" />
                            </v-col>
                            <v-col cols="6">
                                <ButtonComponent style="color: #1C1D28;" color="primary" :block="true" label="Confirm" />
                            </v-col>
                        </v-row>

                    </template>
                </DialogComponent>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import DialogComponent from '@/components/ui/DialogComponent.vue';
import moment from 'moment';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

export default {

    components: {
        DialogComponent,
        ButtonComponent,
    },

    data: () => ({
        todayString: moment().format('YYYY-MM-DD'),
        dates: [],
        isCalendarDialogOpen: false,
        isAllHoldingDialogOpen: false,
        isAllTypesDialogOpen: false,
        type: "BUY",
        transactions: [],
        loading: true,
        startDate: null,
        endDate: null,
        headers: [
            {
                value: "transaction_type",
                text: "Type",
                align: "start",
                sortable: true,
            },

            {
                value: "amount",
                text: "Amount",
                sortable: true,
                align: "start",
            },
        ]
    }),
    methods: {

        allowPastDates(date) {
            // Return true if the date is in the past or today, otherwise false
            // const currentDate = new Date();
            // return date <= currentDate;
        },

        openCalendarDialog() {
            this.isCalendarDialogOpen = true
        },

        openAllHoldingsDialog() {
            this.isAllHoldingDialogOpen = true
        },

        goBack() {
            this.$router.go(-1);
        },

        fetchTransactions() {
            this.loading = true;
            this.$axios
                .get("https://api.uccoin.co/api/v3/earn/transaction")
                .then((response) => {
                    this.transactions = response.data.transaction;
                    this.loading = false;
                });
        },

        updateDialogState(value) {
            this.isCalendarDialogOpen = value;
            this.isAllHoldingDialogOpen = value;
        },

        amountColor(type) {
            if (type == "WITHDRAW") {
                return "redPrimary--text";
            } else {
                return "white--text";
            }
        },
    },

    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
    },

    mounted() {
        this.fetchTransactions();
    },
};
</script>
<style scoped>
p {
    font-size: smaller !important;
}

#balance-card {
    margin-top: 20px !important;
    border-radius: 30px 30px 0 0 !important;
    width: 100%;
    margin: 0;
    padding: 0;
}
</style>
